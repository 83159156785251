import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import CustomSelect, { CustomSelectParqueaderos } from "./Select";
import CustomClearIndicator from "./MultiSelect";

export const FieldBasic = ({ children, errors, ...props }) => {
  return (
    <div className={props.className || `form-group row ${props.label ? "mb-3" : ""}`}>
      {props.label ? (
        <>
          <label className="col-lg-4 col-form-label" htmlFor={props.htmlFor}>
            {props.label}
            {props.required && <span className="text-danger"> *</span>}
          </label>
          <div className="col-lg-7">
            {children}
            {errors && (
              <div
                id={`val-${props.htmlFor}-error`}
                className="invalid-feedback animated fadeInUp"
                style={{ display: "block" }}
              >
                {errors}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="col-12">
          {children}
          {errors && (
            <div
              id={`val-${props.htmlFor}-error`}
              className="invalid-feedback animated fadeInUp"
              style={{ display: "block" }}
            >
              {errors}
            </div>
          )}
        </div>
      )}
    </div>
  );
};


export const SelectVivienda = ({ errors, ...props }) => {

  // URL Dropdown viviendas
  const URL_VIVIENDAS = '/api/v3/usuarios/citofonos/dropdown/';

  // Loading viviendas
  const [viviendasOptions, setViviendasOptions] = useState([]);

  useEffect(() => {
    const fetchViviendaOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_VIVIENDAS);
        const data = await response.data;
        setViviendasOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchViviendaOptions();
  }, [])
  // Fin Loading viviendas

  return (
    {...props.label ? (
      <FieldBasic
        label="Inmueble"
        name="vivienda"
        required
        className={props.className || "form-group row"}
        errors={errors}
      >
        <CustomSelect
          options={viviendasOptions}
          placeholder={props.placeholder || "Selecciona un inmueble"}
          {...props}
        />
      </FieldBasic>
    ) : (
      <FieldBasic
        name="vivienda"
        required
        className={props.className || "form-group row"}
        errors={errors}
      >
        <CustomSelect
          options={viviendasOptions}
          placeholder="Selecciona un inmueble"
          {...props}
        />
      </FieldBasic>
    )}
  )
}


export const MultiSelectSelectVivienda = ({ errors, ...props }) => {

  // URL Dropdown viviendas
  const URL_VIVIENDAS = '/api/v3/usuarios/citofonos/dropdown/';

  // Loading viviendas
  const [viviendasOptions, setViviendasOptions] = useState([]);

  useEffect(() => {
    const fetchViviendaOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_VIVIENDAS);
        const data = await response.data;
        setViviendasOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchViviendaOptions();
  }, [])
  // Fin Loading viviendas

  return (
    <FieldBasic
      label="Inmueble"
      name="vivienda"
      errors={errors}
      {...props}
    >
      <CustomClearIndicator
        options={viviendasOptions}
        defaultsValues={props.defaultsValues}
        {...props}
      />
    </FieldBasic>
  )
}


export const SelectParqueaderos = ({ errors, ...props }) => {

  // URL Dropdown viviendas
  const URL_DROPDOWN_PARQUEADEROS = '/api/v3/visitantes/ingreso-vehicular/dropdown_parqueaderos/';

  // Loading viviendas
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    const fetchViviendaOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_DROPDOWN_PARQUEADEROS);
        const data = await response.data;
        setDropdownOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchViviendaOptions();
  }, [])
  // Fin Loading viviendas

  return (
    {...props.label ? (
      <FieldBasic
        label="Inmueble"
        name="vivienda"
        required
        errors={errors}
      >
        <CustomSelectParqueaderos
          options={dropdownOptions}
          placeholder="Selecciona un parqueadero"
          {...props}
        />
      </FieldBasic>
    ) : (
      <FieldBasic
        name="vivienda"
        required
        errors={errors}
      >
        <CustomSelectParqueaderos
          options={dropdownOptions}
          placeholder="Selecciona un parqueadero"
          {...props}
        />
      </FieldBasic>
    )}
  )
}
