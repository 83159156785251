// SearchInput.js
import React, { useState, useRef, useEffect } from 'react';
import { BotonPrimary } from './BotonesCustom';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { SelectVivienda } from "jsx/components/CitoPlus/components/FieldBasic";


const SearchInputInmuebles = ({ value, onSearchChange, placeholder }) => {

  const [selectedVivienda, setSelectedVivienda] = useState(null);
  const [showButtonClearInmueble, setShowButtonClearInmueble] = useState(false);
  const [showButtonClearConsejo, setShowButtonClearConsejo] = useState(false);

  const defaultFilterParams = {
    search: '',
    consejo_admon: '',
    inmueble: ''
  };

  const [filterParams, setFilterParams] = useState(defaultFilterParams);

  let timeoutId = null;

  const navigate = useNavigate();

  var { parmFilter } = useParams();

  const [searching, setSearching] = useState(false);

  const selectRef = useRef(null);

  const handleInputChange = (e) => {
    const searchName = e.target.name;
    const searchText = e.target.value;
    setSearching(true);

    // Si es consejo_admon y tiene un valor, mostrar botón de limpiar
    if (searchName === 'consejo_admon' && searchText) {
      setShowButtonClearConsejo(true);
    }

    const newFilterParams = {
      ...filterParams,
      [searchName]: searchText
    };

    setFilterParams(newFilterParams);
    delayDebounceFn(newFilterParams);
  };

  const onClearClick = () => {
    setFilterParams(defaultFilterParams);
    setSearching(false);
    setSelectedVivienda(null);
    setShowButtonClearInmueble(false);
    setShowButtonClearConsejo(false);

    if (selectRef.current) {
      selectRef.current.value = "";
    }

    onSearchChange(defaultFilterParams);
    navigate('/inmuebles');
  };

  const handleSearchClick = () => {
    onSearchChange(filterParams);
  };

  const onEnterPress = () => {
    onSearchChange(filterParams);
  };

  useEffect(() => {
    if (parmFilter) {
      const newFilterParams = {
        ...filterParams,
        search: parmFilter,
      };
      setFilterParams(newFilterParams);
      setSearching(true);
      onSearchChange(newFilterParams);
    }
  }, [parmFilter]);

  const delayDebounceFn = (newFilterParams) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onSearchChange(newFilterParams);
    }, 900);
  };

  const handleClearInmuebleClick = () => {
    setShowButtonClearInmueble(false);
    setSelectedVivienda(null);

    const newFilterParams = {
      ...filterParams,
      inmueble: ''
    };
    setFilterParams(newFilterParams);
    onSearchChange(newFilterParams);
  };

  const handleClearConsejoClick = () => {
    setShowButtonClearConsejo(false);
    if (selectRef.current) {
      selectRef.current.value = "";
    }

    const newFilterParams = {
      ...filterParams,
      consejo_admon: ''
    };
    setFilterParams(newFilterParams);
    onSearchChange(newFilterParams);
  };

  return (
    <div className="row align-items-center">
      <div className="d-flex align-items-center gap-2 flex-grow-1">
        <div style={{ width: '20%' }}>
          <input
            autoFocus
            id="search"
            name="search"
            type="text"
            autoComplete='on'
            value={filterParams.search}
            className="form-control form-control-sm"
            placeholder={(placeholder && placeholder) || 'Buscar...'}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onEnterPress && onEnterPress();
              }
            }}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ width: '35%' }} className="d-flex gap-2">
          <div className="flex-grow-1">
            <SelectVivienda
              id="inmueble"
              name="inmueble"
              isSearchable="true"
              isClearable="true"
              className="basic-single"
              placeholder="Inmuebles"
              key="inmueble"
              value={selectedVivienda}
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '38px',
                  height: '38px'
                })
              }}
              onChange={(selectedOptions) => {
                setSelectedVivienda(selectedOptions);
                setShowButtonClearInmueble(!!selectedOptions);

                const newFilterParams = {
                  ...filterParams,
                  inmueble: selectedOptions ? selectedOptions.label : '',
                };
                setFilterParams(newFilterParams);
                delayDebounceFn(newFilterParams);
              }}
            />
          </div>
        </div>
        <div style={{ width: '35%' }} className="d-flex gap-2">
          <div className="flex-grow-1">
            <select
              ref={selectRef}
              className="form-control form-control-sm"
              id="consejo_admon"
              name="consejo_admon"
              value={filterParams.consejo_admon}
              onChange={handleInputChange}
            >
              <option value="" disabled>Consejo</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="d-flex gap-2" style={{ width: '20%' }}>
          <BotonPrimary onClick={handleSearchClick}>
            <i className="fas fa-search" />
          </BotonPrimary>
          <BotonPrimary onClick={onClearClick}>
            <i className="fas fa-trash" />
          </BotonPrimary>
        </div>
      </div>
    </div>
  );
};

export default SearchInputInmuebles;
