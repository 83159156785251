import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Formik, Field } from "formik";
import Loading from "../components/loading";
import * as Yup from "yup";
import axiosInstance from "../../../../services/AxiosInstance";
import QuillEditor from "../components/field_quill";
import swal from "sweetalert";
import DatePicker from 'react-datepicker'; // Asegúrate de importar correctamente
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { format, parse, isValid } from 'date-fns';
import CustomSelect from "../components/Select";
import { BotonInfoText, BotonPrimaryText } from "../components/BotonesCustom";
import { LinkDanger } from "../components/CitoPlusLink";
import Modal from "react-bootstrap/Modal";
import { notifySuccess } from "jsx/components/CitoPlus/components/CitoPlusToastr";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";

import 'quill/dist/quill.snow.css'; // Importar estilos de Quill
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';

const formCarteleraSchema = Yup.object().shape({
  msg: Yup.string()
    .required("Por favor, ingresa un mensaje.")
    .nullable(true),
  dirigido_a: Yup.string()
    .required("Por favor, selecciona un grupo.")
})

export const FormCartelera = () => {
  const URL = "/api/v3/home/carteleras/";
  const initialValuesEmpty = {
    msg: "",
    fecha_limite: null,
    dirigido_a: ""
  };

  const optionsDirigidoA = [
    { slug: "0", nombre: 'TODOS' },
    { slug: "1", nombre: 'VIGILANTES' },
    { slug: "2", nombre: 'RESIDENTES' },
  ];

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [showPreview, setShowPreview] = useState(false); // Estado para controlar el modal
  const [previewContent, setPreviewContent] = useState(""); // Estado para el contenido de la vista previa

  const slug = useParams();
  const navigate = useNavigate();

  const formatProgramacionEnvio = (date) => {
    let parsedDate;

    // Verificamos si la fecha es válida y si es un objeto Date
    if (date instanceof Date) {
        parsedDate = date;
    } else {
        // Intentamos parsear la fecha en el formato dd/MM/yyyy
        parsedDate = parse(date, 'dd/MM/yyyy', new Date());
    }

    // Verificamos si la fecha es válida
    if (!isValid(parsedDate)) {
        return null; // O maneja el error como prefieras
    }

    // Formateamos la fecha a yyyy-MM-dd
    return format(parsedDate, 'yyyy-MM-dd');
  };

  useEffect(() => {
    if (slug && slug.slug !== undefined) {
      const fetchInitialValues = async () => {
        try {
          const response = await axiosInstance.get(`${URL}${slug.slug}/`);
          const data = await response.data;
          setInitialValues(data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchInitialValues();
    } else {
      setLoading(false);
    }
  }, [slug]);

  if (loading) {
    return (
      <>
        <PageTitle activeMenu="Mensajeria" motherMenu="Crear Cartelera" />
        <Loading />
      </>
    );
  }

  const handleSubmit = async (values) => {
    setLoadingBtn(true);
    try {
      const postData = {
        ...values,
        fecha_limite: values.fecha_limite ? formatProgramacionEnvio(values.fecha_limite) : null,
      };
      if (slug && slug.slug !== undefined) {
        await axiosInstance.patch(`${URL}${slug.slug}/`, postData);
        notifySuccess("Cartelera Editada");
      } else {
        await axiosInstance.post(URL, postData);
        notifySuccess("Cartelera Creada");
      }
      navigate('/envio_masivo_cartelera');
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingBtn(false);
    }
  };

  // Manejar vista previa
  const handlePreview = (content) => {
    setPreviewContent(content);
    setShowPreview(true);
  };

  return (
    <>
      <div className="d-flex alin-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Crear Cartelera</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formCarteleraSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label" htmlFor="email">
                    <label htmlFor="descripcion">Mensaje </label>
                    <span className="text-danger"> * </span>
                  </div>
                  <Field
                    component={QuillEditor}
                    name="msg"
                    id="msg"
                    placeholder="Escribe algo..."
                    onEditorChange={(value) => setFieldValue('msg', value)}
                  />
                </div>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label">
                    <label className="form-label">Fecha Limite</label>
                  </div>
                  <div className="col-lg-7">
                    <DatePicker
                      id="progarmacion_envio"
                      name="progarmacion_envio"
                      // selected={values.fecha_limite ? new Date(values.fecha_limite) : null}
                      selected={
                        values.fecha_limite
                          ? parse(values.fecha_limite, 'dd/MM/yyyy', new Date())
                          : null
                      }
                      onChange={(date) => {
                        // Si la fecha es válida, la formateamos a dd/MM/yyyy
                        if (isValid(date)) {
                          setFieldValue('fecha_limite', format(date, 'dd/MM/yyyy'));
                        } else {
                          setFieldValue('fecha_limite', null);
                        }
                      }}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Seleccionar fecha..."
                      isClearable
                    />
                    <div id="val-progarmacion_envio-error" className="invalid-feedback animated fadeInUp" style={{ display: "block" }}>
                      {errors.progarmacion_envio}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label" htmlFor="dirigido_a">
                    <label htmlFor="dirigido_a">Dirigido A</label>
                  </div>
                  <div className="col-lg-7">
                    <CustomSelect
                      id="dirigido_a"
                      name="dirigido_a"
                      options={optionsDirigidoA}
                      defaultsValues={values.dirigido_a.toString()}
                      onChange={(selectedOptions) => {
                        setFieldValue('dirigido_a', selectedOptions ? selectedOptions.value : null);
                      }}
                    />
                    <div id="val-dirigido_a-error" className="invalid-feedback animated fadeInUp" style={{ display: "block" }}>
                      {errors.dirigido_a}
                    </div>
                  </div>
                </div>

                {loadingBtn ? (
                    <Loading />
                  ) : (
                    <div className="modal-footer">
                      <BotonInfoText
                        type="button"
                        onClick={() => handlePreview(values.msg)} // Manejar vista previa
                      >
                        <i className="fa fa-eye me-2"></i>
                        Vista Previa
                      </BotonInfoText>
                      <LinkDanger
                        to='/envio_masivo_email'
                      >
                        <i className="fa fa-times me-2"></i>
                        Cancelar
                      </LinkDanger>
                      <BotonPrimaryText
                        type="submit"
                        disabled={isSubmitting}>
                          <i className="fa fa-save me-2"></i>
                        Agregar
                      </BotonPrimaryText>
                    </div>
                  )}

              </form>
            )}
          </Formik>

          {/* Modal de vista previa */}
          <Modal show={showPreview} onHide={() => setShowPreview(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Vista Previa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: previewContent }} />
            </Modal.Body>
            <Modal.Footer>
              <BotonPrimaryText onClick={() => setShowPreview(false)}>
                Cerrar
              </BotonPrimaryText>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default FormCartelera;
