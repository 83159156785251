import React from 'react';
import { Spinner } from 'react-bootstrap';
import noImagen from '../../../../images/no-image.jpg';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const VigilantesCustomCard = ({
  title,
  subtitle,
  inmueble,
  image,
  items,
  footerButtons,
  loading,
  imageClickable,
  className = "col-xl-12"
}) => {
  const imageSource = image || noImagen;

  return (
    <div className={`${className} mb-4`}> {/* Separa las tarjetas */}
      <div className="card h-100 shadow-sm">
        <div className="card-body p-3">
          {loading ? (
            <div className="text-center py-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column flex-md-row mb-3">
                <div className="flex-shrink-0 mb-2 mb-md-0 me-md-3" style={{ width: '100px' }}>
                  {imageSource && (
                    <a
                      href={imageSource}
                      data-lightbox={`imagen-${title}`}
                      data-title={title}
                      className={!imageClickable ? "pointer-events-none" : ""}
                    >
                      <img
                        src={imageSource}
                        className="rounded img-fluid shadow-sm"
                        alt={title || "Imagen"}
                        style={{
                          cursor: imageClickable ? 'pointer' : 'default',
                          maxHeight: '100px',
                          objectFit: 'cover',
                          width: '100%'
                        }}
                        onError={(e) => { e.target.src = noImagen }}
                      />
                    </a>
                  )}
                </div>
                <div className="flex-grow-1">
                  {title && <h5 className="mb-1">{title}</h5>}
                  <div className="listline-wrapper mb-4">
                    {subtitle &&
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-subtitle">No. Parqueadero</Tooltip>}
                      >
                        <span className="item">
                          <i className="fa fa-parking text-primary" aria-hidden="true me-2"></i>
                          {_.toUpper(subtitle)}
                        </span>
                      </OverlayTrigger>
                    }
                    {inmueble ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-inmueble">Inmueble</Tooltip>}
                      >
                        <span className="item">
                          <i className="fa fa-building text-primary" aria-hidden="true me-2"></i>
                          {inmueble}
                        </span>
                      </OverlayTrigger>
                      ) : (
                        <span className="item">
                          <i className="fa fa-building text-primary" aria-hidden="true me-2"></i>
                          {'-- Sin inmueble --'}
                        </span>
                      )
                    }

                  </div>


                  {items && items.length > 0 && (
                    <div className="mt-2">
                      <div className="row g-2">
                        {items.map((item, index) => (
                          <div key={index} className="col-lg-6 col-md-12">
                            <div className="d-flex align-items-center">
                              <strong className="me-2 text-nowrap small">{item.title}:</strong>
                              <span className="text-truncate small">{item.value || '-'}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {footerButtons && footerButtons.length > 0 && (
                <div className="d-flex justify-content-end mt-2">
                  {footerButtons.map((button, index) => (
                    <div key={index} className="ms-2">
                      {button}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VigilantesCustomCard;
