import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

import { CurrencyComponent } from '../components/CurrencyComponnet';

import DatePickerRanger from '../components/DatePickerRanger';

import { formatDate } from '../utils';
import swal from 'sweetalert';
import FacturaParking from './FacturaPark';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CitoPlusLinkIconPrimary, LinkPrimary } from '../components/CitoPlusLink';
import FormAgregarRercursosZonaSocial from './FormAgregarRercursosZonaSocial';
import { notifyError } from '../components/CitoPlusToastr';


export const ZonasSociales = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  // Totales del recuado
  const [sumaTotalRecaudo, setSumaTotalRecaudo] = useState(0);
  const [sumaTotalGraciaRecaudo, setSumaTotalGraciaRecaudo] = useState(0);

  // Opern Collapse
  const [openFilter, setOpenFilter] = useState(false);

  // Dates and filters del recaudo
  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const [dateRangeEnd, setDateRangeEnd] = useState([null, null]);
  const [startDateEnd, endDateEnd] = dateRangeEnd;

  // Modal Facturacion
  const [showModalFacturacion, setShowModalFacturacion] = useState(false);
  const [showModalFacturacionSlug, setShowModalFacturacionSlug] = useState(null);

  const URL = '/api/v3/zonas-sociales/zonas/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterParams, setFilterParams] = useState({
    search: '',
    h_ingreso_after: '',
    h_ingreso_before: '',
    h_salida_after: '',
    h_salida_before: '',
  });

  // Agregar Recursos
  const [showModalAddRecurso, setShowModalAddRecursos] = useState(false);
  const [selectedZonaSocialUUID, setSelectedZonaSocialUUID] = useState(null);

  const handleModalAgregarRecursos = (zonaSocialUUID) => {
    setSelectedZonaSocialUUID(zonaSocialUUID);
    setShowModalAddRecursos(true);
  }

  // FIN Agregar Recursos

  const columns = useMemo(() => [
    {
      id: 'nombre',
      Header: 'Nombre ',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return (
          <>
            <div className='right-align-cell'>
              <p className="">{row.nombre}</p>
              <p className=''>Recursos: {row.zona_recursos.length}</p>
              <p className=''>Valor: {row.valor}</p>
            </div>
          </>
        )
      },
    },
    {
      id: 'responsable',
      Header: 'Responable ',
      disableFilters: true,
      disableSortable: false,
      accessor: 'responsable',
    },
    {
      id: 'horario',
      Header: 'Horario ',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return (
          <>
            <div className='right-align-cell'>
              <p className="">Entrada: {row.horario_inicio}</p>
              <p className="">Salida: {row.horario_fin}</p>
            </div>
          </>
        )
      }
    },
    {
      id: 'capacidad',
      Header: 'Capacidad ',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (
          <>
            <div className='right-align-cell'>
              <p className="paragraph-left">Max: {row.capacidad_max}</p>
              <p className="paragraph-left">Min: {row.capacidad_min}</p>
            </div>
          </>
        )
      }
    },
    {
      Header: 'Acciones',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (
          <div className="form-group">
            <ButtonGroup aria-label="Botones de acciones zona social">
              <CitoPlusLinkIconPrimary
                to={"#"}
                title="Agregar Recurso"
                onClick={() => {
                  handleModalAgregarRecursos(row.uuid);
                }}
              >
                <i className="fas fa-plus"></i>
              </CitoPlusLinkIconPrimary>
              <CitoPlusLinkIconPrimary
                title="Ver detalles"
                to={`/zona-social/detalle/${row.uuid}`}
              >
                <i className="fas fa-eye"></i>
              </CitoPlusLinkIconPrimary>
              <CitoPlusLinkIconPrimary
                title="Editar"
                to={`/zona-social/editar/${row.uuid}`}
              >
                <i className="fas fa-pencil-alt"></i>
              </CitoPlusLinkIconPrimary>
            </ButtonGroup>
          </div>
        )
      }
    }
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  useEffect(() => {
    if (filterApplied) {
      fetchUrl(1);
      setFilterApplied(false);
    }
  }, [filterParams, filterApplied]);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;

    setLoading(true);

    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);

      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario

  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Zonas Sociales</h4>
        <LinkPrimary
          to={'/zonas-sociales/crear/'}
        >
          <i className="fas fa-plus me-2"></i>
          Crear Zona
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              totalRecords={totalRecords}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}

        <FacturaParking
          isOpen={showModalFacturacion}
          onClose={() => setShowModalFacturacion(false)}
          slug={showModalFacturacionSlug}
        />

        {/* MODAL AGREGAR RECURSO ZONA SOCIAL */}
        <FormAgregarRercursosZonaSocial
          showModal={showModalAddRecurso}
          onClose={() => {
            setShowModalAddRecursos(false);
            setSelectedZonaSocialUUID(null);
          }}
          updateList={fetchUrl}
          selectedZonaSocialUUID={selectedZonaSocialUUID}
        />
        {/* */}

      </div>

    </>
  );
}

export default ZonasSociales;
