import React, { useState, useEffect, useMemo } from "react";
import { useTable, useFilters, usePagination } from "react-table";
import axiosInstance from "../../../../../services/AxiosInstance";
import Tables from "jsx/components/CitoPlus/components/Tables";

export const Pagos = () => {
  const URL = `/api/v3/pagos/historial/`;

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const [dataPaginated, setDataPaginated] = useState({
    current_page: "",
    total_pages: "",
    next: "",
    previous: "",
    first_page: "",
    last_page: "",
    count: "",
  });

  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: "referencia",
        Header: "Referencia",
        disableFilters: true,
        disableSortable: false,
        accessor: "referencia",
      },
      {
        id: "created_at",
        Header: "Creado",
        disableFilters: true,
        disableSortable: false,
        accessor: "created_at",
      },
      {
        id: "estado",
        Header: "Estado",
        disableFilters: true,
        disableSortable: false,
        accessor: "estado",
      },
      {
        id: "valortotal",
        Header: "Valor total",
        disableFilters: true,
        disableSortable: false,
        accessor: "valortotal",
      },
      {
        id: "codigorespuesta",
        Header: "Código respuesta",
        disableFilters: true,
        disableSortable: false,
        accessor: "codigorespuesta",
      },
      {
        id: "metodo_pago",
        Header: "Método de pago",
        disableFilters: true,
        disableSortable: false,
        accessor: "metodo_pago",
      },
      {
        id: "descripcion",
        Header: "Descripción",
        disableFilters: true,
        disableSortable: false,
        accessor: "descripcion",
      },
      {
        id: "descripcion_rs",
        Header: "Descripción RS",
        disableFilters: true,
        disableSortable: false,
        accessor: "descripcion_rs",
      },
      {
        id: "codigoseguimiento",
        Header: "Código de seguimiento",
        disableFilters: true,
        disableSortable: false,
        accessor: "codigoseguimiento",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination
  );

  const formatoMetodoPago = (metodoID) => {
    let valor = "";
    switch (metodoID) {
      case 1:
        valor = "Pago por PSE.";
        break;

      case 2:
        valor = "Tarjeta de crédito.";
        break;

      default:
        valor = "Sin valor asignado.";
        break;
    }

    return valor;
  };

  const formatoCodigoRespuesta = (codigoID) => {
    let valor = "";
    switch (Number(codigoID)) {
      case 0:
        valor = "Transacción exitosa.";
        break;

      case 1:
        valor = "Transacción rechazada.";
        break;

      case 2:
        valor = "Error inesperado.";
        break;

      case 3:
        valor = "Procesando.";
        break;

      case 4:
        valor = "Transacción reversada.";
        break;

      case 99:
        valor = "Validación con preguntas.";
        break;

      default:
        valor = "Sin valor asignado.";
        break;
    }

    return valor;
  };

  useEffect(() => {
    fetchUrl(1)
  }, []);

  const fetchUrl = async ({ pageIndex = 1, search = "", ordering = [], url = "" }) => {
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        return;
      }
      const response = await axiosInstance.get(URL, {
        params: {
          page: pageIndex,
          ordering: ordering,
        },
      });

      response.data.results.map((value, key) => {
        if (value["metodo_pago"]) {
          response.data.results[key]["metodo_pago"] = formatoMetodoPago(
            value["metodo_pago"]
          );
        }

        if (value["codigorespuesta"]) {
          response.data.results[key]["codigorespuesta"] =
            formatoCodigoRespuesta(value["codigorespuesta"]);
        }

        response.data.results[key]["valortotal"] = `${new Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
        }).format(value["valortotal"])}`;
      });

      setDataPaginated({
        ...((({ current_page, total_pages, next, previous, first_page, last_page, count }) => ({
          current_page,
          total_pages,
          next,
          previous,
          first_page,
          last_page,
          count,
        }))(response.data)),
      });

      setTotalPages(response.data.total_pages);
      setData(response.data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 404) {
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder =
      sortField === id ? (order === "asc" ? "desc" : "asc") : "asc";

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl({pageIndex: 1, search: '', ordering: `${newOrder === "desc" ? "-" : ""}${id}`});
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="fs-20 mb-0 me-auto">Pagos</h4>
      </div>

      <div className="card">
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              setLoading={setLoading}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              sortField={sortField}
              order={order}
              page={page}
              dataPaginated={dataPaginated}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Pagos;
