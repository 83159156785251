import axiosInstance from "./AxiosInstance";
import { confirmedGetPermisosAction } from "../store/actions/PermisosActions";
import {
    Logout,
  } from '../store/actions/AuthActions';

export function permisosServices() {
    return axiosInstance.get('/api/v3/usuarios/permisos/');
}

export function checkPermissions(dispatch, navigate) {
    const permisosString = localStorage.getItem('userPermissions');
    let permisos = '';
    if (!permisosString) {
        dispatch(Logout(navigate));
        return;
    }

    permisos = JSON.parse(permisosString);
    dispatch(confirmedGetPermisosAction(permisos));
}

export function savePermissionsInLocalStorage(permisos) {
    localStorage.setItem('userPermissions', JSON.stringify(permisos));
    return permisos
}
