/// Menu
//import Metismenu from "metismenujs";
import { useSelector } from 'react-redux';
import React, { useReducer, useContext, useEffect, useState, useRef } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, Dropdown } from 'react-bootstrap';
/// Link
import { Link } from "react-router-dom";



import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';


import { MenuList } from './Menu';
import { MenuListVigilante } from './MenuVigilante';
import { MenuListConsejo } from './MenuConsejo';
import { MenuEmpty } from './MenuEmpty';

import { getPermissions } from '../../../store/selectors/PermissionSelectors';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

export function NavMenuToggle() {
  // setTimeout(() => {
  //   let mainwrapper = document.querySelector("#main-wrapper");
  //   if (mainwrapper.classList.contains('menu-toggle')) {
  //     mainwrapper.classList.remove("menu-toggle");
  //   }
  // }, 200);
}

const SideBar = () => {
  const sidebarRef = useRef(null); // Ref for sidebar
  let d = new Date();

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const allPermissions = useSelector(getPermissions);

  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )


  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })
    }
  }

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const auth = JSON.parse(localStorage.getItem('userDetails'));

  // Cargar MENU
  const tipoUsuario = useSelector(state => state.auth.auth.user.tipo);
  // Extraer el estado completo para los permisos
  const appState = useSelector(state => state);
  const [ConditionalMenuList, setConditionalMenuList] = useState([]);

  useEffect(() => {
    if (tipoUsuario === 'VIGILANTE') {
      // Dynamically import the conditional menu list
      setConditionalMenuList(() => MenuListVigilante);
    } else if (tipoUsuario === 'CONSEJO') {
      // Dynamically import the conditional menu list
      setConditionalMenuList(() => MenuListConsejo);
    } else if (tipoUsuario === 'ADMIN') {
      // Use the default menu list
      setConditionalMenuList(() => MenuList);
    } else {
      // Use the empty menu list
      setConditionalMenuList(() => MenuEmpty);
    }
  }, [tipoUsuario]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    NavMenuToggle()
  };

  const checkPermissionsMenu = (permission) => {
    if (permission === "" || permission === undefined) {
      return true;
    }
    if (!allPermissions.hasOwnProperty(permission)) {
      return false;
    }
    if (allPermissions.hasOwnProperty(permission)) {
      return allPermissions[permission];
    }
    return false
  }

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      onClick={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <div className="header-profile2 i-false c-pointer">
          <div className="header-info2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center sidebar-info">
              <div>
                {auth.user.first_name && auth.user.last_name ? (
                  <span className="font-w400 d-block">{auth.user.first_name} {auth.user.last_name}</span>
                ) : (
                  <span className="font-w400 d-block">{auth.user.email}</span>
                )}
                <small className="text-end font-w400">{auth.user.username}</small>
              </div>
            </div>
            <div className="ml-auto">
              <LogoutPage />
            </div>
          </div>
        </div>
        <ul className="metismenu" id="menu">
          {ConditionalMenuList.map((data, index) => {
            if (!checkPermissionsMenu(data.permission)) {
              return null;
            }
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>{data.title}</li>
              )
            } else {
              return (
                <li className={`${state.active === data.title ? 'mm-active' : ''} ${path === data.to ? "mm-active" : ""}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(data.title) }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                          {data.content && data.content.map((subData, idx) => {
                            if (checkPermissionsMenu(subData.permission)) {
                              return (
                                <li key={idx}
                                  className={`${state.activeSubmenu === subData.title ? "mm-active" : ""} ${path === subData.to ? "mm-active" : ""}`}
                                >
                                  {subData.content && subData.content.length > 0  ?
                                    <>
                                      <Link
                                        to={"#"}
                                        className={`${path === subData.to ? "active" : ""} sub-menu-link ${subData.hasMenu ? 'has-arrow' : ''}`}
                                        onClick={() => { handleSubmenuActive(subData.title) }}
                                      >
                                        {subData.title}
                                      </Link>
                                      <Collapse in={state.activeSubmenu === subData.title ? true : false}>
                                        <ul
                                          className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}
                                          style={{ padding: 'revert' }}
                                        >
                                          {subData.content && subData.content.map((subSubData, index) => {
                                            if (checkPermissionsMenu(subSubData.permission)) {
                                              return (
                                                <li key={index}>
                                                  <Link className={`${path === subSubData.to ? "mm-active" : ""} sub-menu-link`} to={subSubData.to}>{subSubData.title}</Link>
                                                </li>
                                              )
                                            }
                                            return null;
                                          })}
                                        </ul>
                                      </Collapse>
                                    </> :
                                    <Link
                                      to={subData.to}
                                      className={`${path === subData.to ? "active" : ""} sub-menu-link`}
                                    >
                                      {subData.title}
                                    </Link>
                                  }
                                </li>
                              )
                            }
                            return null;
                          })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link
                      to={data.to}
                      className={`${path === data.to ? "active" : ""}`}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              )
            }
          })}
        </ul>
        <div className="copyright">
          <p><strong>CitoPlus</strong> © {d.getFullYear()} All Rights Reserved</p>
          <p className="fs-12">Made with <span className="heart" onClick={() => heartBlast()}></span> by CitoPlus</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
