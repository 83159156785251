import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from 'services/AxiosInstance.js';
import { notifySuccess, notifyError } from '../../components/CitoPlusToastr';
import { SpinnerCitoPlus } from 'jsx/components/CitoPlus/components/loadingLogo';
import { set } from 'lodash';

const ModalNotaCredito = ({ show, onHide, facturaId }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    motivo: '',
    monto: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post(`/api/v3/parking/facturacion-electronica/${facturaId}/nota-de-credito/`, formData);
      if (response.status === 201 || response.status === 200) {
        setFormData({
            motivo: '',
            monto: '',
        });
        notifySuccess('Nota de crédito creada exitosamente');
        onHide();
      }
    } catch (error) {
      notifyError('Error al crear la nota de crédito');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crear Nota de Crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center p-4">
            <SpinnerCitoPlus text="Creando nota de crédito..." />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crear Nota de Crédito</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="motivo">
            <Form.Label>Motivo</Form.Label>
            <Form.Control
              type="text"
              name="motivo"
              value={formData.motivo}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="monto" className="mt-3">
            <Form.Label>Monto</Form.Label>
            <Form.Control
              type="number"
              name="monto"
              value={formData.monto}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
            {loading ? 'Creando...' : 'Crear Nota de Crédito'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNotaCredito;
