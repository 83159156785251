import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SmallModal from '../../components/small_modal';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';
import '../../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../../services/DefaultParamets';
import AddEditInmueblesModal from './AddEditModal';
import { BotonPrimary, BotonDanger, BotonDangerText, BotonPrimaryText } from '../../components/BotonesCustom';
import { LinkPrimary, LinkSecondary } from '../../components/CitoPlusLink';
import Tables from '../../components/tables';
import SearchInputInmuebles from 'jsx/components/CitoPlus/components/input_search_inmuebles';
import Swal from "sweetalert2";
import { notifySuccess, notifyError } from '../../components/CitoPlusToastr';
import { ButtonGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { SpinnerCitoPlus } from "jsx/components/CitoPlus/components/loadingLogo";
import { handleErrors } from 'jsx/components/CitoPlus/components/ErrorHandlingComponent';

export const Unidades = () => {
  const URL = "/api/v3/usuarios/citofonos/";

  const perfil = useSelector(state => state.auth.auth.user);

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [selectedInmuebleId, setSelectedInmuebleId] = useState(null);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const [formAddEditInmueble, setformAddEditInmueble] = useState(false);

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // Modal Importar
  const [modalImportar, setModalImportar] = useState(false);
  const [importFile, setImportFile] = useState(null); // Estado para el archivo importado
  const [datosImportacion, setDatosImportacion] = useState([]);

  const columns = useMemo(() => [
    {
      id: 'interior',
      Header: 'Inmueble ',
      accessor: 'interior',
      disableFilters: true,
      disableSortable: false,
      // Filter: ColumnFilter
    },
    {
      id: 'nombre',
      Header: 'Nombre',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return (
          <>
            <ul>
              <li>{row.nombre}</li>
              <li>{row.email}</li>
            </ul>
          </>
        );
      },
    },
    {
      id: 'numeros',
      Header: () => (
        <div className="left-align-header">Números</div>
      ),
      accessor: row => {
        const data = [
          { number: 1, value: row.numero1 },
          { number: 2, value: row.numero2 },
          { number: 3, value: row.numero3 },
          { number: 4, value: row.numero4 },
          { number: 5, value: row.numero5 },
        ];

        const result = data.map(({ number, value }) => {
          return `${number}. ${value !== null ? value : ''}`;
        })

        return result.join('<br/>');
      },
      Cell: ({ value }) => (
        <div className="left-align-cell" dangerouslySetInnerHTML={{ __html: value }} />
      ),
      disableFilters: true,
      disableSortable: true
    },
    {
      id: 'grupo',
      Header: 'Grupo',
      accessor: (row) => {
        // Verifica si 'grupo' es un array y tiene al menos un elemento
        if (Array.isArray(row.grupo) && row.grupo.length > 0) {
          // Mapea los nombres de los grupos y devuelve una lista
          const groupNames = row.grupo.map((group) => group.nombre);
          return <ul>{groupNames.map((name, index) => <li key={index}>{index + 1}: {name}</li>)}</ul>;
        } else {
          return '';
        }
      },
      disableFilters: true,
      disableSortable: true
    },
    {
      id: 'slug',
      Header: 'Acciones',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (
          <>
            <div className='form-group'>
              <ButtonGroup aria-label="Botones de acciones visitantes">
                <BotonPrimary
                  title="Editar"
                  onClick={() => {
                    setEditMode(true);
                    setSelectedInmuebleId(row.slug);
                    setformAddEditInmueble(true);
                  }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </BotonPrimary>
                {row.email !== null && row.consejo_admon ? (
                  <BotonPrimary
                    title="Enviar Credenciales Consejo"
                    onClick={() => {
                      axiosInstance.get(
                        `/api/v3/usuarios/citofonos/${row.slug}/send-acceso-consejo/`
                      )
                      .then((response) => {
                        notifySuccess("Usuario y contraseña de residentes enviadas al correo de cada inmueble");
                      })
                      .catch((error) => {
                        console.error("Error fetching data:", error);
                        notifyError("Error al enviar las contraseñas");
                    })
                  }
                }
                  >
                    <i className='fas fa-user'></i>
                  </BotonPrimary>
                ) : null}
                {row.email !== null ? (
                  <BotonPrimary
                    title="Enviar Credenciales"
                    onClick={() => {
                      axiosInstance.get(
                        `/api/v3/usuarios/citofonos/send_password/?inmueble_uuid=${row.slug}`
                      )
                      .then((response) => {
                        notifySuccess("Usuario y contraseña de residentes enviadas al correo de cada inmueble");
                      })
                      .catch((error) => {
                        console.error("Error fetching data:", error);
                        notifyError("Error al enviar las contraseñas");
                      })
                    }}
                  >
                    <i className="fas fa-paper-plane"></i>
                  </BotonPrimary>
                ) : null}
                <BotonDanger
                  title="Eliminar Inmueble"
                  onClick={() => {
                    Swal.fire({
                      title: `¿Estás seguro de eliminar este inmueble? ${row.interior}`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Si, Eliminar',
                      confirmButtonColor: '#ff5e17',
                      cancelButtonText: 'No, Cancelar',
                      cancelButtonColor: '#0f2242',
                      reverseButtons: true,
                    }).then(willDelete => {
                      if (willDelete.isConfirmed) {
                          setLoading(true);
                          axiosInstance.delete(`${URL}${row.slug}/`).then(() => {
                            notifySuccess('Inmueble eliminado correctamente');
                            updateInmueblesList();
                          }).catch((error) => {
                            notifyError('Error al eliminar el inmueble');
                          }).finally(() => {
                            setLoading(false);
                          });
                      }
                    })
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </BotonDanger>
              </ButtonGroup>
            </div>
          </>
        )
      },
    }
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchUnidades = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  const fetchUrl = async (pageIndex = 1, search = {}, ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        '/api/v3/usuarios/citofonos/',
        {
          params: {
            ...defaultParams,
            ...search,
            page: pageIndex,
            order_by: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const updateInmueblesList = async () => {
    await fetchUrl(currentPage, searchValue, `${order === 'desc' ? '-' : ''}${sortField}`);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedFetchUnidades(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleFileChange = (event) => {
    setImportFile(event.target.files[0]);
  };

  const handleImport = async () => {
    if (!importFile) return;

    const formData = new FormData();
    formData.append('file_import', importFile);

    try {
      const response = await axiosInstance.post('/api/v3/usuarios/inmuebles/importar/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        notifySuccess(`Importación exitosa ${response.data.total} inmuebles, ${response.data.fallos} inmuebles fallidos`);
        updateInmueblesList();
        setImportFile(null); // Resetea el archivo seleccionado
        setDatosImportacion(response.data);
        // setModalImportar(false);
      } else {
        notifyError('Error en la importación');
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Inmuebles</h4>
        {perfil.perfil_extra.citofono_activar ? (
          <LinkSecondary
            to={"#"}
            onClick={() => {
              axiosInstance.get("/api/v3/usuarios/citofonos/send_password/")
                .then((response) => {
                  notifySuccess("Usuario y contraseña de residentes enviadas al correo de cada inmueble");
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  notifyError("Error al enviar las contraseñas");
                })
            }}
          >
            <i className="fas fa-sync-alt me-2"></i>
            Activar App
          </LinkSecondary>
        ) : null}
        {perfil.perfil_extra.citofono_importar ? (
          <LinkSecondary
            to={"#"}
            onClick={() => {
              setModalImportar(true);
            }}
          >
            <i className="fas fa-file-import me-2"></i>
            Importar
          </LinkSecondary>
        ) : null}
        {perfil.perfil_extra.citofono_exportar ? (
          <LinkSecondary
            to={"#"}
            onClick={() => {
              axiosInstance({
                url: "/api/v3/usuarios/citofonos/exportar/",
                method: "GET",
                responseType: 'blob'
              })
                .then((response) => {
                  const href = global.URL.createObjectURL(response.data);
                  const link = document.createElement('a');
                  link.href = href;
                  link.setAttribute('download', 'citofonos_exportar.csv'); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  global.URL.revokeObjectURL(href);
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                })
            }}
          >
            <i className="fas fa-file-export me-2"></i>
            Exportar
          </LinkSecondary>
        ) : null}
        <LinkPrimary
          to={"#"}
          onClick={() => {
            setformAddEditInmueble(true)
            setEditMode(false)
            setSelectedInmuebleId(null)
          }}>
          <i className="fas fa-plus me-2"></i>
          Agregar Inmueble
        </LinkPrimary>
        <AddEditInmueblesModal
          showModal={formAddEditInmueble}
          onClose={() => {
            setformAddEditInmueble(false);
            setSelectedInmuebleId(null);
          }}
          editMode={editMode}
          updateInmueblesList={updateInmueblesList}
          selectedInmuebleId={selectedInmuebleId}
        />
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInputInmuebles
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>
              <SpinnerCitoPlus text="Cargando datos..." />
            </p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
              totalRecords={totalRecords}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <SmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onClose={() => setSmallShowModal(false)}
          />
        )}

        {modalImportar ? (
          <Modal show={modalImportar} onHide={() => setModalImportar(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Importar</Modal.Title>
            </Modal.Header>
            <form>
              <Modal.Body>
                <div className="form-group">
                  <input
                    type="file"
                    className="form-control"
                    id="file_import"
                    name='file_import'
                    onChange={handleFileChange} // Captura el archivo
                  />
                </div>
                {datosImportacion && (
                  <div>
                    <h3>{datosImportacion.message}</h3>
                    {datosImportacion.fallos_list && (
                      <ul>
                        {datosImportacion.fallos_list.map((fallos, index) => (
                          <li key={index}>{fallos.nombre}, {fallos.vivienda}, {fallos.email}, {fallos.mensaje} </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <BotonDangerText onClick={() => setModalImportar(false)}>
                  <i className="fas fa-times me-2"></i>
                  Cerrar
                </BotonDangerText>
                <BotonPrimaryText onClick={handleImport}>
                  <i className="fas fa-upload me-2"></i>
                  Importar
                </BotonPrimaryText>
              </Modal.Footer>
            </form>
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default Unidades;
