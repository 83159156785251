import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../../services/AxiosInstance";
import swal from "sweetalert";
import Loading from "../../components/loading";
import { BotonPrimaryText, BotonDangerText } from "../../components/BotonesCustom";
import { FieldBasic, MultiSelectSelectVivienda } from "../../components/FieldBasic";
import { notifySuccess } from "jsx/components/CitoPlus/components/CitoPlusToastr";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";


const formGruposSChema = Yup.object().shape({
  nombre: Yup.string()
    .nullable(true)
    .required("Por favor, ingresa el nombre."),
  descripcion: Yup.string()
    .required("Por favor, selecciona el color.")
    .nullable(true),

})

const MinutaCategoriaModal = ({
  showModal, onClose, editMode, url, uuidMinuta = null
}) => {

  const initialValuesEmpty = {
    nombre: null,
    color: null
  }

  const URL = "/api/v3/usuarios/grupos_copropiedades/";

  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  useEffect(() => {
    const loadInitialValues = async () => {
      setLoadingInitialValues(true);
      try {
        if (editMode) {
          const data = await fetchDetailsGrupos();
          const initialValues = {
            nombre: data.nombre,
            descripcion: data.desc,
            visible_porteria: data.visible_porteria,
            estado: data.estado,
            viviendas: data.viviendas
          };
          setInitialValues(initialValues);
        } else {
          const initialValues = initialValuesEmpty;
          setInitialValues(initialValues);
        }
      } catch (error) {
        console.error("Error fetching initial values:", error);
      } finally {
        setLoadingInitialValues(false);
      }
    };
    loadInitialValues();

  }, [editMode, uuidMinuta]);

  const fetchDetailsGrupos = async () => {
    try {
      const response = await axiosInstance.get(`${URL}${uuidMinuta}/`);
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm, setFieldError }) => {
    const postData = {
      ...values
    }
    try {
      let response = null;
      if (editMode && uuidMinuta) {
        response = await axiosInstance.put(`${url}${uuidMinuta}/`, postData);
      } else {
        response = await axiosInstance.post(url, postData);
      }
      const data = await response.data;
      setInitialValues(initialValuesEmpty);
      onClose();
      const textSwal = editMode ? 'Grupo Editado' : 'Grupo Creado'
      swal(textSwal, values.interior, "success");
    } catch (error) {
      handleErrors(error);
    } finally {
      setSubmitting(false);
    }
  }

  const handleCloseModal = () => {
    onClose();
    setLoadingInitialValues(false);
    setInitialValues({ initialValuesEmpty });
  }

  return (
    // add modal large
    <Modal show={showModal} onHide={onClose} size="lg">
      {loadingInitialValues ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={formGruposSChema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <div role='document'>
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {editMode ? "Editar Grupo" : "Crear Grupo"}
                  </h5>
                </div>
                <div className="modal-body">


                  <div className="form-group mb-3 row">

                    <div className="form-group mb-3 row">
                      <div className='col-lg-4 col-form-label' htmlFor="interior">
                        Nombre
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control"
                          id="nombre"
                          name="nombre"
                          value={values.nombre || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          id="val-nombre-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.nombre}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <div className='col-lg-4 col-form-label' htmlFor="interior">
                        Color
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-7">
                        <input
                          type="color"
                          className="form-control"
                          id="color"
                          name="color"
                          value={values.color || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          id="val-color-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.color}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <BotonDangerText
                    type="button"
                    onClick={onClose}
                  >
                    Cancelar
                  </BotonDangerText>
                  <BotonPrimaryText
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Guardar
                  </BotonPrimaryText>
                </div>

              </form>
            </div>
          )}
        </Formik>
      )}

    </Modal>
  );
};

export default MinutaCategoriaModal;
