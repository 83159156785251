import { createSelector } from 'reselect';

const selectPermissions = (state) => state.permissions;

export const getPermissions = createSelector(
    [selectPermissions],
    (permissions) => permissions?.permisos_desde_perfil || {}
    );

export const selectPermission = (permissionName) =>
  createSelector(
    [selectPermissions],
    (permissions) => permissions?.permisos_desde_perfil?.[permissionName] || false
  );


export const selectGrupos = createSelector(
    [selectPermissions],
    (permissions) => permissions?.grupos || []
    );
