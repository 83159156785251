import React, { useContext } from "react";
import { useSelector } from "react-redux";

/// React router dom
import { Routes, Route, Outlet } from 'react-router-dom'

import { selectPermission } from "../store/selectors/PermissionSelectors";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import "../css/style_citoplus.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from './pages/ScrollToTop';
/// Dashboard
import Home from "./components/Dashboard/Home";

//Citoplus
import Unidades from "./components/CitoPlus/administradores/Citofonia/Unidades";

// Grabacion de llamadas
import GrabacionesLlamadas from "./components/CitoPlus/administradores/GrabacionLlamadas/lists";

// Gestion ADM
import GrupoCopropiedad from "./components/CitoPlus/administradores/GruposCopropiedad/Grupo";
import PQR from "./components/CitoPlus/administradores/GestionAdmin/PQR";
import Autorizaciones from "./components/CitoPlus/administradores/GestionAdmin/Autorizaciones";
import Documentos from "./components/CitoPlus/administradores/GestionAdmin/Documentos";
import FormAutorizaciones from "./components/CitoPlus/administradores/GestionAdmin/FormAutorizaciones";
import FormDocumentos from "./components/CitoPlus/administradores/GestionAdmin/FormDocumentos";

// Mensajeria
import EnvioMasivoCorreo from "./components/CitoPlus/EnvioMasivo/Correo";
import EnvioMasivoSMS from "./components/CitoPlus/EnvioMasivo/Sms";
import Carteleras from "./components/CitoPlus/EnvioMasivo/Cartelera";
import EmailTemplateCreate from "./components/CitoPlus/EnvioMasivo/EmailTemplateCreate";
import FormCrearEmail from "./components/CitoPlus/EnvioMasivo/FormCrearEmail";
import EmailDetail from "./components/CitoPlus/EnvioMasivo/EmailDetail";
import FormSMS from "./components/CitoPlus/EnvioMasivo/FormSMS";
import FormCartelera from "./components/CitoPlus/EnvioMasivo/FormCartelera";
import CarteleraDetail from "./components/CitoPlus/EnvioMasivo/CarteleraDetail";

// Parqueaderos
import Parqueaderos from "./components/CitoPlus/Parking/Parqueaderos";
import Vehiculos from "./components/CitoPlus/Parking/Vehiculos";
import PlanoParqueaderos from "./components/CitoPlus/Parking/PlanoParqueaderos";

// Seguridad
import Vigilantes from "./components/CitoPlus/Seguridad/Vigilantes";
import Minuta from "./components/CitoPlus/Seguridad/Minuta";
import MinutaCategorias from "jsx/components/CitoPlus/administradores/Seguridad/MinutasCategorias";
import Correspondencia from "./components/CitoPlus/Seguridad/Correspondencia";
import RegistrosVisitantes from "./components/CitoPlus/Seguridad/Registro";
import RegistroVisitanteDetalle from "./components/CitoPlus/Seguridad/RegistroVisitanteDetalle";

// Facturacion
import Visitantes from "./components/CitoPlus/Facturacion/FacturacionVehicular";
import VisitanteDetalle from "./components/CitoPlus/Facturacion/FacturacionVehicularDetalle";
import FacturaParking from "./components/CitoPlus/Facturacion/FacturaPark";
import VigilantesCajas from "./components/CitoPlus/Facturacion/VigilantesCajas";
import ZonasSociales from "./components/CitoPlus/Facturacion/ZonasSociales";
import FormZonaSocial from "./components/CitoPlus/Facturacion/FormZonaSocial";
import FormAgregarRercursosZonaSocial from "./components/CitoPlus/Facturacion/FormAgregarRercursosZonaSocial";
import ZonaSocialDetalle from "./components/CitoPlus/Facturacion/ZonaSocialDetalle";
import FormEditarZonaSocial from "./components/CitoPlus/Facturacion/FormEditarZonaSocial";
import ZonaSocialRecursos from "./components/CitoPlus/Facturacion/ZonaSocialRecurso";
import FacturaElectronica from "./components/CitoPlus/Facturacion/FacturaElectronica";
import NotasCreditos from "./components/CitoPlus/administradores/FacturacionElectronica/NotasCreditos";

// Mi Cuenta
import CambioClave from "./components/CitoPlus/Configuraciones/CambioPassword";
import EditarPerfil from "./components/CitoPlus/Configuraciones/EditarPerfil";
import NotificacionesConfiguraciones from "./components/CitoPlus/administradores/Configuraciones/Notificaciones";
import ConfiguracionFacturacion from "./components/CitoPlus/administradores/Configuraciones/FacturacionElectronica";

// Soporte
import Tutoriales from "./components/CitoPlus/administradores/Soporte/Tutoriales";

// PSE
import CargarSaldoSMS from "./components/CitoPlus/administradores/pse/cargar_saldo_sms";
import PagarServicio from "./components/CitoPlus/administradores/pse/pagar_servicio";
import Pagos from "./components/CitoPlus/administradores/pse/Pagos";
import Contratos from "./components/CitoPlus/administradores/pse/Contratos";

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import { store } from "store/store";

const Markup = () => {

  // Permisos por el momento solo de facturacion electronica, esta guardado en el redux/store
  const perm_facturacion_electronica = useSelector(selectPermission('facturacion_electronica'));

  // const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const allroutes = [
    /// allroutes
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    // Citofonos
    { url: "inmuebles/:parmFilter?", component: <Unidades /> },
    { url: "grupo_copropiedad", component: <GrupoCopropiedad /> },
    { url: 'grabacion_llamadas', component: <GrabacionesLlamadas /> },

    // Envio Masivo.
    { url: "envio_masivo_email", component: <EnvioMasivoCorreo /> },
    { url: "envio_masivo_sms", component: <EnvioMasivoSMS /> },
    { url: "envio_masivo_cartelera", component: <Carteleras /> },
    { url: "crear_template_email", component: <EmailTemplateCreate /> },
    { url: "create_email_masivo/:slug?/:reenviar?", component: <FormCrearEmail /> },
    { url: "email-detail/:slug", component: <EmailDetail />, title: "Detalle Email" },
    { url: "crear-sms/:slug?", component: <FormSMS /> },
    { url: "form-cartelera/:slug?", component: <FormCartelera /> },
    { url: "cartelera-detalles/:slug", component: <CarteleraDetail /> },

    // Gestion Admin
    { url: "pqr", component: <PQR /> },
    { url: "autorizaciones", component: <Autorizaciones /> },
    { url: "documentos", component: <Documentos /> },
    { url: "form-agregar-autorizacion/:slug?", component: <FormAutorizaciones /> },
    { url: "form-agregar-documento/:slug?", component: <FormDocumentos /> },

    // Parking
    { url: "parqueaderos", component: <Parqueaderos /> },
    { url: "vehiculos", component: <Vehiculos /> },
    { url: "plano_parqueaderos", component: <PlanoParqueaderos /> },

    // Seguridad
    { url: "vigilantes", component: <Vigilantes /> },
    { url: "minutas", component: <Minuta /> },
    { url: "minuta-categorias", component: <MinutaCategorias /> },
    { url: "correspondencia", component: <Correspondencia /> },
    { url: "registros_visitantes", component: <RegistrosVisitantes /> },
    { url: "registros-visitantes/:slug", component: <RegistroVisitanteDetalle /> },

    // configuraciones
    { url: "cambio_clave", component: <CambioClave /> },
    { url: "editar_perfil", component: <EditarPerfil /> },
    { url: "configuracion-facturacion-electronica", component: perm_facturacion_electronica ? <ConfiguracionFacturacion /> : null, },
    { url: "notificaciones", component: <NotificacionesConfiguraciones /> },

    // Soporte
    { url: "tutoriales", component: <Tutoriales /> },

    // Facturacion
    { url: "facturacion-vehicular/", component: <Visitantes /> },
    { url: "facturacion-vehicular/:slug", component: <VisitanteDetalle /> },
    { url: "factura-parking/:slug", component: <FacturaParking /> },
    { url: "vigilantes-cajas", component: <VigilantesCajas /> },
    { url: "reservaciones", component: <ZonasSociales /> },
    { url: "zonas-sociales", component: <ZonasSociales /> },
    { url: "zonas-sociales/crear/", component: <FormZonaSocial /> },
    { url: "zona-social-recursos/agregar/:uuid", component: <FormAgregarRercursosZonaSocial /> },
    { url: "zona-social/detalle/:uuid", component: <ZonaSocialDetalle /> },
    { url: "zona-social/editar/:uuid", component: <FormEditarZonaSocial /> },
    { url: "zonas-sociales/recursos/:uuid?", component: <ZonaSocialRecursos /> },
    { url: "facturacion-electronica/", component: perm_facturacion_electronica ? <FacturaElectronica /> : null },
    { url: "notas-creditos", component: <NotasCreditos /> },

    // PSE
    { url: "cargar-saldo-sms", component: <CargarSaldoSMS /> },
    { url: "pagar-servicio", component: <PagarServicio /> },
    { url: "pagos", component: <Pagos /> },
    { url: "contratos", component: <Contratos /> },

  ];
  //let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  // let pagePath = path.split("-").includes("page");
  return (
    <>
      <Routes>
        <Route path='page-lock-screen' element={<LockScreen />} />
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
              title={data.title}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
};

export default Markup
