// En el archivo donde está definida la función formatDateTime
import { format } from "date-fns";

export const formatDateTime = (date) => {
  const parseDate = new Date(date);
  let formattedDate;

  if (isNaN(parseDate.getTime())) {
    formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
  } else {
    formattedDate = format(parseDate, "yyyy-MM-dd HH:mm:ss");
  }
  return formattedDate;
};

export const formatDate = (date) => {
  const parseDate = new Date(date);
  let formattedDate;

  if (isNaN(parseDate.getTime())) {
    formattedDate = format(date, "yyyy-MM-dd");
  } else {
    formattedDate = format(parseDate, "yyyy-MM-dd");
  }
  return formattedDate;
};


export const LectorDeBarras = (e, { setFieldValue }) => {
  const ee = e || window.event;

  if (ee.altKey || ee.ctrlKey) {
    ee.preventDefault();
  }

  /*
    Detectamos keyCode 9 == TAB, para los lectores SAT.
    Cambiamos el key TAB por ' con el fin de mantener la compatibilidad
    con los honeypot xenon que ya fueron configurados asi.
  */
  if (ee.keyCode === 9) {
    ee.preventDefault();
    let v = e.target.value;
    v = v + "'";
    e.target.value = v;
    return ee.which;
  }

  switch (ee.which) {
    case 32:  // Space
      ee.preventDefault();
      break;
    case 13:  // Enter
      /*
          Los lectores SAT en la posicion 4 regresa el sexo
          por eso si es igual a 1 solo coloco la posicion 3.
      */
      e.preventDefault();
      const s = e.target.value;
      const sl = s.split("'");
      e.target.value = sl[0];
      if (sl.length > 4) {

        let nombre = "";
        let apellido = "";
        let identificacion = sl[0];

        if (sl[4].length === 1) {
          nombre = sl[3];
        } else {
          nombre = sl[3] + " " + sl[4];
        }
        apellido = sl[1] + " " + sl[2];

        setFieldValue("nombre", `${nombre} ${apellido}`);
        setFieldValue("identificacion", identificacion);
      }
      break;
    case 161:  // ¡
      ee.preventDefault();
      break;
    default:
      return e.which;
  }
  e.preventDefault();
};

export const validacionPlaca = (e, { setFieldValue }) => {
  const currentValue = e.target.value;
  // Remueve todo lo que no sea letras o números
  const sanitizedValue = currentValue.replace(/[^a-zA-Z0-9]/g, '');
  setFieldValue("placa", sanitizedValue);
}
