    import React, { useState, useEffect } from 'react';
import DatePickerRanger from '../components/DatePickerRanger';
import { BotonPrimary } from './BotonesCustom';
import { formatDate } from '../utils';
import { SelectVivienda } from "jsx/components/CitoPlus/components/FieldBasic";

const BusquedaVisitantes = ({ value, onSearchChange, onEnterPress, onClearClick, placeholder, ...propsDatePicker }) => {

    const [showButonClear, setShowButonClear] = useState(false);

    // Dates and filters del recaudo
    const [dateRangeStart, setDateRangeStart] = useState([null, null]);
    const [startDateStart, endDateStart] = dateRangeStart;
    const [inputDateSearchKey, setInputDateSearchKey] = useState('daterange-key');

    const defaultFilterParams = {
        search: '',
        date_after: '',
        date_before: '',
    }

    const [filterParams, setFilterParams] = useState(defaultFilterParams);

    const showDatePicker = useState(propsDatePicker && propsDatePicker.showDatePicker || false);

    let timeoutId = null;

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const delayDebounceFn = (newFilterParams) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            onSearchChange(newFilterParams);
        }, 900);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setShowButonClear(true);
        const newFilterParams = {
            ...filterParams,
            search: value
        };
        setFilterParams(newFilterParams);
        delayDebounceFn(newFilterParams);
    };

    const handleSearchClick = () => {
        onSearchChange(filterParams);
    };

    const [selectedVivienda, setSelectedVivienda] = useState(null);

    const handleClearClick = () => {
        setShowButonClear(false);
        setDateRangeStart([null, null]);
        setSelectedVivienda(null);

        const newFilterParams = {
            ...defaultFilterParams,
            search: ''
        };
        setInputDateSearchKey(inputDateSearchKey + 1);
        setFilterParams(newFilterParams);
        onSearchChange(newFilterParams);
    };

    return (
        <div className="d-flex align-items-center w-100">
            <div className="d-flex align-items-center gap-2 flex-grow-1">
                <div style={{ width: '20%' }}>
                    <input
                        autoFocus
                        type="text"
                        autoComplete='off'
                        value={filterParams.search}
                        className="form-control form-control-sm"
                        placeholder={placeholder || 'Buscar...'}
                        onChange={handleInputChange}
                        style={{ height: '38px' }}
                    />
                </div>

                <div style={{ width: '25%' }}>
                    <SelectVivienda
                        id="inmueble"
                        name="inmueble"
                        isSearchable="true"
                        isClearable="true"
                        className="basic-single"
                        placeholder="Inmuebles"
                        key="inmueble"
                        value={selectedVivienda}
                        styles={{
                            control: (base) => ({
                                ...base,
                                minHeight: '38px',
                                height: '38px'
                            })
                        }}
                        onChange={(selectedOptions) => {
                            setShowButonClear(true);
                            setSelectedVivienda(selectedOptions);
                            const newFilterParams = {
                                ...filterParams,
                                search: selectedOptions ? selectedOptions.label : '',
                            };
                            delayDebounceFn(newFilterParams);
                        }}
                    />
                </div>

                <div style={{ width: '35%' }}>
                    <DatePickerRanger
                        startDate={startDateStart}
                        endDate={endDateStart}
                        key={inputDateSearchKey}
                        onChange={(update) => {
                            setShowButonClear(true);
                            const newFilterParams = {
                                ...filterParams,
                                date_after: update[0] ? formatDate(update[0]) : '',
                                date_before: update[1] ? formatDate(update[1]) : '',
                            };
                            setDateRangeStart(update);
                            setFilterParams(newFilterParams);
                            delayDebounceFn(newFilterParams);
                        }}
                        customInput={
                            <input
                              style={{
                                height: "38px",
                                width: "100%"
                              }}
                            />
                          }
                        withPortal
                        {...propsDatePicker}
                    />
                </div>
                <div className="d-flex gap-2" style={{ width: '20%' }}>
                    <BotonPrimary onClick={handleSearchClick}>
                        <i className="fas fa-search" />
                    </BotonPrimary>
                    {showButonClear && (
                        <BotonPrimary onClick={handleClearClick}>
                            <i className="fas fa-times" />
                        </BotonPrimary>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusquedaVisitantes;
