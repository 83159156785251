import { getProfile, updateProfile } from "../../services/PostsService"
import { CONFIRMED_GET_PROFILE, CONFIRMED_UPDATED_PROFILE } from "./ActionsTypes"
import swal from "sweetalert";
import { handleErrors } from "../../jsx/components/CitoPlus/components/ErrorHandlingComponent";
import { notifySuccess } from "../../jsx/components/CitoPlus/components/CitoPlusToastr";

export function getProfileAction() {
  return (dispatch, getState) => {
    getProfile()
      .then((response) => {
        dispatch(confirmedGetProfileAction(response.data));
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      })
  };
}

export function confirmedGetProfileAction(profileData) {
  return {
    type: CONFIRMED_GET_PROFILE,
    profileData
  };
}

export function updateProfileAction(profileData) {
  return (dispatch, getState) => {
    updateProfile(profileData)
      .then((response) => {
        dispatch(confirmedUpdatedProfileAction(response.data));
        notifySuccess('Profile updated successfully');
      })
      .catch((error) => {
        handleErrors(error);
      })
  };
}

export function confirmedUpdatedProfileAction(profileData) {
  return {
    type: CONFIRMED_UPDATED_PROFILE,
    profileData
  };
}
