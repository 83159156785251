import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import { Link } from "react-router-dom";
import { Badge, ButtonGroup } from 'react-bootstrap';
import { LinkPrimary, CitoPlusLinkIconPrimary, CitoPlusLinkIconDanger } from '../components/CitoPlusLink';
import ModalFormParqueadero from './ModalParqueaderoForm';
import Swal from 'sweetalert2';
import { notifySuccess, notifyError } from '../components/CitoPlusToastr';



export const Parqueaderos = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const URL = '/api/v3/parking/parqueaderos/';
  const titleCard = "Parqueaderos"

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    { id: 'no_parqueadero', Header: '# de Parqueadero', accessor: 'no_parqueadero', disableFilters: true, disableSortable: false },
    {
      id: 'tipo_parqueadero', Header: 'Tipo', accessor: 'tipo_parqueadero', disableFilters: true, disableSortable: false,
      Cell: ({ value }) => {
        return (
          <Badge className="badge-light-primary" pill>
            {value}
          </Badge>
        );
      }
    },
    {
      id: 'entra_en_rotacion',
      Header: 'Rotación',
      accessor: 'entra_en_rotacion',
      disableFilters: true,
      disableSortable: true,
      Cell: ({ value }) => {
        return (
          <Badge className="badge-light-primary" pill>
            {(value) ? 'Activo' : 'Inactivo'}
          </Badge>
        );
      }
    },
    {
      id: 'estatus',
      Header: 'Activo',
      accessor: 'estatus',
      disableFilters: true,
      disableSortable: true,
      Cell: ({ value }) => {
        return (
          <Badge className="badge-light-primary" pill>
            {(value) ? 'Activo' : 'Inactivo'}
          </Badge>
        );
      }
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <ButtonGroup aria-label="Botones de acciones">
          <CitoPlusLinkIconPrimary
            title="Editar"
            to={`#/${row.slug}`}
            onClick={() => {
              handleOpenModalForm(row.slug, true)
            }}
          >
            <i className="fas fa-pencil-alt"></i>
          </CitoPlusLinkIconPrimary>
          <CitoPlusLinkIconDanger
            title="Eliminar"
            to={`#/${row.slug}`}
            onClick={() => {
              handleEliminar(row.slug, false)
            }}
          >
            <i className="fas fa-trash"></i>
          </CitoPlusLinkIconDanger>
        </ButtonGroup>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);

      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  // Modal de Agregar o editar Parqueadero
  const [showModalForm, setShowModalForm] = useState(false);
  const [selectedParqueaderoUUID, setSelectedParqueaderoUUID] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleOpenModalForm = (selectedParqueaderoUUID, editMode) => {
    setShowModalForm(true);
    setSelectedParqueaderoUUID(selectedParqueaderoUUID);
    setEditMode(editMode);
  };

  const handleCloseModalForm = () => {
    setShowModalForm(false);
    setSelectedParqueaderoUUID(null);
    setEditMode(false);
  }
  // Fin Modal de Agregar o editar Parqueadero

  // Eliminar parqueadero
  const handleEliminar = (slug, editMode) => {
    Swal.fire({
      title: '¿Deseas eliminar este parqueadero?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`${URL}${slug}/`)
          .then((response) => {
            if (response.status === 204) {
              notifySuccess('Parqueadero eliminado correctamente');
              fetchUrl(1);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            if (error.response && error.response.status === 404) {
              notifyError('Página no encontrada');
            } else {
              notifyError('Error al eliminar parqueadero');
            };
          });
      }
    })
  }

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Parqueaderos</h4>
        <LinkPrimary
          to={`#`}
          className="ms-auto"
          onClick={() => {
            handleOpenModalForm(null, false);
          }}
        >
          <i className='fas fa-plus me-2'></i>
          Crear Parqueadero
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        {/* Modal de Agregar o editar Parqueadero */}
        <ModalFormParqueadero
          showModal={showModalForm}
          onClose={handleCloseModalForm}
          updateList={fetchUrl}
          selectedParqueaderoUUID={selectedParqueaderoUUID}
          editMode={editMode}
        />
        {/* Fin Modal de Agregar o editar Parqueadero */}
      </div>

    </>
  );
};

export default Parqueaderos;
