import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import Loading from "../components/loading";
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";

export const RegistroVisitanteDetalle = () => {
  const params = useParams();
  const URL = `/api/v3/visitantes/ingreso/${params.slug}/`;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchVisitante = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL);
      const visitanteData = response.data;
      setData(visitanteData);
    } catch (error) {
      if (error.response) {
        const errorMessages = Object.values(error.response.data).flat();
        const errorMessage = errorMessages.join("\n");
        Swal.fire('Error', errorMessage, "error");
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVisitante();
  }, []);

  if (loading) {
    return <Loading />;
  }

  // Función para obtener el valor de una clave con validación de primer nivel
  const getValueWithValidation = (key, defaultValue = "") => {
    const value = data[key];
    if (value !== null && value !== undefined && value !== "") {
      return value;
    } else {
      return data.visitante && data.visitante[key] ? data.visitante[key] : defaultValue;
    }
  };

  // Función para renderizar un elemento de detalle
  const renderDetailItem = (label, value) => (
    <div className="mb-3 d-flex">
      <div className="col">
        <h5 className="mb-1 fs-14 custom-label">{label}:</h5>
      </div>
      <div className="col">
        <span>{value}</span>
      </div>
    </div>
  );

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header d-block">
              <h4 className="fs-20 d-block">Detalles del Visitante</h4>
            </div>
            <div className="card-body pt-4">
              {renderDetailItem("Nombre", getValueWithValidation("nombre"))}
              {renderDetailItem("Apellidos", getValueWithValidation("apellidos"))}
              {renderDetailItem("Identificación", getValueWithValidation("identificacion"))}
              {renderDetailItem("Fecha de Creación", getValueWithValidation("created_at"))}
              {renderDetailItem("Unidad", getValueWithValidation("unidad"))}
              {renderDetailItem("Hora de Ingreso", getValueWithValidation("h_ingreso"))}
              {renderDetailItem("Hora de Salida", getValueWithValidation("h_salida"))}
              {renderDetailItem("Vigilante de Ingreso", getValueWithValidation("vigilante_ingreso_full_name"))}
              {renderDetailItem("Vigilante de Salida", getValueWithValidation("vigilante_salida_full_name"))}
              {renderDetailItem("Ingreso", getValueWithValidation("ingreso") ? "Sí" : "No")}
              {renderDetailItem("Unidad de Visita", getValueWithValidation("unidad_visita"))}
              {renderDetailItem("Autorizado", getValueWithValidation("autorizado"))}
              {renderDetailItem("Tipo de Ingreso", getValueWithValidation("tipo_de_ingreso"))}
              {renderDetailItem("Nota", getValueWithValidation("nota"))}
              {renderDetailItem("Tipo de Vehículo", getValueWithValidation("tipo_vehiculo"))}
              {renderDetailItem("Vehículo", getValueWithValidation("vehiculo"))}
              {renderDetailItem("ARL", getValueWithValidation("arl"))}
              {renderDetailItem("EPS", getValueWithValidation("eps"))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistroVisitanteDetalle;
