import { CONFIRMED_GET_PERMISSIONS } from "../actions/ActionsTypes";

const initialState = {
    "permisos_desde_perfil": {
        facturacion_electronica: false,
        citofono_importar: false,
        citofono_exportar: false,
        citofono_activar: false,
        vendor_sip: false
    },
    "grupos": [],
}

export const PermissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIRMED_GET_PERMISSIONS:
            return {
                ...state,
                ...action.permisosData
            }
        default:
            return state
    }
}
