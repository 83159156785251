import { CONFIRMED_GET_PROFILE, CONFIRMED_UPDATED_PROFILE } from "../actions/ActionsTypes";

const initialState = {
  profile: {
    user: {
      first_name: "",
      last_name: "",
      last_login: "",
      email: "",
      date_joined: ""
    },
    unidad: "",
    pais: "",
    ciudad: "",
    direccion: "",
    telefono_fijo: "",
    telefono_celular: "",
    fecha_vencimiento: "",
    multiples_porterias: "",
    cuadrante: "",
    logo: null,
    cant_citofonos: "",
    alt_nombre: "",
    alt_fijo: "",
    alt_celular: "",
    alt_email: "",
    park_min_gracia: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: ""
  }
}

const profileReducer = (state = initialState, action) => {
  if (action.type === CONFIRMED_GET_PROFILE) {
    return {
      ...state,
      profile: action.profileData
    }
  }
  if (action.type === CONFIRMED_UPDATED_PROFILE) {
    return {
      ...state,
      profile: action.profileData
    }
  }
  return state
}

export default profileReducer;
