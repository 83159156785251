export const CREATE_POST_ACTION = '[Post Action] Create Post';
export const CONFIRMED_CREATE_POST_ACTION =
    '[Post Action] Confirmed Create Post';
export const GET_POSTS = '[Post Action] Get Posts';
export const CONFIRMED_GET_POSTS = '[Post Action] Confirmed Get Posts';
export const EDIT_POST_ACTION = '[Post Action] Edit Post';
export const CONFIRMED_EDIT_POST_ACTION =
    '[Post Action] Confirmed Edit Post';
export const CONFIRMED_DELETE_POST_ACTION =
    '[Post Action] Confirmed Delete Post';


export const CONFIRMED_GET_PROFILE = '[Post Action] Confirmed Get Profile';
export const CONFIRMED_UPDATED_PROFILE = '[Post Action] Confirmed Update Profile';

export const CONFIRMED_GET_PERMISSIONS = '[Permissions Action] Confirmed Get Permissions';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
