import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useTable, useFilters, usePagination } from "react-table";
import "../../table/FilteringTable/filtering.css";
import axiosInstance from "../../../../services/AxiosInstance";
import { getDefaultParams } from "../../../../services/DefaultParamets";
import Tables from "jsx/components/CitoPlus/components/tables";
import { CurrencyComponent } from "../components/CurrencyComponnet";
import DatePickerSingle from "../components/DatePickerSingle";

import { formatDate } from "../utils";
import FacturaParking from "./FacturaPark";
import { ButtonGroup } from "react-bootstrap";
import {
  CitoPlusLinkIconPrimary,
  LinkPrimary,
  LinkDanger,
} from "../components/CitoPlusLink";
import {
  BotonPrimary,
  BotonPrimaryText,
  BotonDangerText,
} from "../components/BotonesCustom";
import { notifyError, notifySuccess } from "../components/CitoPlusToastr";
import Comentarios from "jsx/components/CitoPlus/components/sidebar_comments";

import ModalCambiarTextoFactura from "./ModalCambiarTextoFactura";

import {
  WhatsAppIcono,
  DescargarIcono,
  NotaIcono,
  HistorialIcono,
  BloqueoIcono,
} from "jsx/components/CitoPlus/components/Icons/BootstrapIconos";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import { Button } from "react-bootstrap";
import ModalNotaCredito from "jsx/components/CitoPlus/administradores/FacturacionElectronica/ModalCrearNotaCredito";
import { SpinnerCitoPlus } from "jsx/components/CitoPlus/components/loadingLogo";
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const FacturaElectronica = () => {
  // Variable para mostrar el mensaje cargando
  const [loading, setLoading] = useState(true);

  // Generación de la data correpondiente a la tabla
  const [data, setData] = useState([]);

  // Generación de las variables para los filtros
  const [searchValue, setSearchValue] = useState("");
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  // Generación de variables para la paginación de la tabla
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Totales del recuado
  const [sumaTotalRecaudo, setSumaTotalRecaudo] = useState(0);
  const [sumaTotalGraciaRecaudo, setSumaTotalGraciaRecaudo] = useState(0);
  const [totalFacturasElectronicas, setTotalFacturasElectronicas] = useState(0);

  // Fecha inicio y fin del recaudo
  const [startDateStart, setDateRangeStart] = useState(null);
  const [startDateEnd, setDateRangeEnd] = useState(null);

  // Nota de Credito
  const [facturaID, setFacturaID] = useState(null);
  const [showModalNotaCredito, setShowModalNotaCredito] = useState(false);

  const handleShowNotaCredito = (id) => {
    setFacturaID(id);
    setShowModalNotaCredito(true);
  };

  const handleCloseNotaCredito = () => {
    setShowModalNotaCredito(false);
    setFacturaID(null);
  };

  // Estado para el modal de visualización de factura
  const [showFacturaModal, setShowFacturaModal] = useState(false);

  // Generación de las variables de URL para el consumo de servicios
  const URL = "/api/v3/parking/facturacion-electronica/";
  const URL_TOTALES = "/api/v3/parking/facturacion-electronica/totales/";
  const URL_EXPORT = "/api/v3/parking/facturacion-electronica/export/";
  const URL_NOTAS = "/api/v3/vigilancia/visitantes-comentarios/";

  // Gegenración de las variables para los parametros de consulta
  const [defaultParams, setDefaultParams] = useState(getDefaultParams);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterParams, setFilterParams] = useState({
    search: "",
    h_ingreso_after: "",
    h_ingreso_before: "",
    h_salida_after: "",
    h_salida_before: "",
  });

  const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: "consecutivo",
        Header: "# ",
        disableFilters: true,
        disableSortable: false,
        // accessor: "consecutivo",
        accessor: (row) => {
          return (
            <>
              <p></p>
              {row.factura_url ? (
                // Case 1: Has invoice - show consecutive
                row.consecutivo_factura_electronica
              ) : row.feel_result && !row.feel_result.Success ? (
                // Case 2: Unsuccessful invoice - show warning badge with tooltip
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {row.feel_result.Message}
                    </Tooltip>
                  }
                >
                  <Badge bg="warning">
                    {row.consecutivo_factura_electronica} <i className="fas fa-exclamation-triangle"></i>
                  </Badge>
                </OverlayTrigger>
              ) : (
                // Case 3: No feel_result - show error badge
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      Error al generar factura
                    </Tooltip>
                  }
                >
                  <Badge bg="danger">
                    {row.consecutivo_factura_electronica} <i className="fas fa-times"></i>
                  </Badge>
                </OverlayTrigger>
              )}
            </>
          );
        }
      },
      {
        Header: "Ingreso y Salida",
        disableFilters: true,
        disableSortable: false,
        accessor: (row) => {
          return (
            <>
              <div>
                <p>Ingreso: {row.h_ingreso}</p>
                <p>Salida: {row.h_salida}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Unidad ",
        disableFilters: true,
        disableSortable: true,
        accessor: "unidad_visita",
      },
      {
        Header: "Vehículo ",
        disableFilters: true,
        disableSortable: true,
        accessor: "vehiculo",
      },
      {
        Header: "Tarifas",
        disableFilters: true,
        disableSortable: true,
        accessor: (row) => {
          return (
            <>
              <div className="right-align-cell">
                <p className="paragraph-left">
                  Total: {row.total.toLocaleString("es-ES")}
                </p>
                {row.notas_credito > 0 && (
                  <p className="paragraph-left">
                    Notas de crédito: {row.notas_credito}
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: "Acciones",
        disableFilters: true,
        disableSortable: true,
        accessor: (row) => {
          return (
            <>
              <div className="form-group">
                <ButtonGroup aria-label="Botones de acciones visitantes">
                  {row.factura_datos_visitante?.telefono !== "" ? (
                    <CitoPlusLinkIconPrimary
                      title="Envió facturación por Correo Electrónico"
                      onClick={() => {
                        sendFacturaWhatsapp(row.factura_datos_visitante.telefono, row.slug);
                      }}
                      disabled={loadingWhatsapp}
                    >
                      {loadingWhatsapp ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <WhatsAppIcono />
                      )}
                    </CitoPlusLinkIconPrimary>
                  ) : null}
                  {row.factura_url !== null && (
                    <>
                      <CitoPlusLinkIconPrimary
                        title="Ver Factura"
                        onClick={() => {
                          verFacturaElectronica(row.factura_url);
                        }}
                      >
                        <DescargarIcono />
                      </CitoPlusLinkIconPrimary>
                      <CitoPlusLinkIconPrimary
                        title="Nota de credito"
                        to={"#"}
                        onClick={() => handleShowNotaCredito(row.slug)}
                      >
                        <NotaIcono />
                      </CitoPlusLinkIconPrimary>
                    </>
                  )}
                  <CitoPlusLinkIconPrimary
                    href="#"
                    onClick={() => {
                      handleComentariosClick(row.comentarios, row.slug);
                    }}
                  >
                    <i className="fas fa-comments"></i>
                    {row.total_comentarios > 0 && (
                      <span
                        className="badge bg-light rounded-circle position-absolute top-0 start-100 translate-middle"
                        style={{ zIndex: 1, color: "black", fontSize: "12px" }}
                      >
                        {row.total_comentarios}
                      </span>
                    )}
                  </CitoPlusLinkIconPrimary>
                </ButtonGroup>
              </div>
            </>
          );
        },
      },
    ],
    [loadingWhatsapp]
  );

  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   return function (...args) {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => func(...args), delay);
  //   };
  // };

  // const debouncedfetchUrl = useCallback(
  //   debounce((pageIndex, search) => {
  //     fetchUrl(pageIndex, search);
  //   }, 500), // El valor 500 es el tiempo de espera en milisegundos
  //   []
  // );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  useEffect(() => {
    if (filterApplied) {
      fetchUrl(1);
      setFilterApplied(false);
    }
  }, [filterParams, filterApplied]);

  const fetchUrl = async (pageIndex = 1, search = "", ordering = []) => {
    defaultParams.page = pageIndex;

    if (searchValue !== "") {
      defaultParams.search = searchValue;
      search = searchValue;
    } else {
      defaultParams.search = search;
    }

    // Cargar totales
    try {
      const response = await axiosInstance.get(URL_TOTALES, {
        params: {
          ...filterParams,
        },
      });
      setSumaTotalRecaudo(response.data.total);
      if (response.data.total_tiempo_gracia === null) {
        response.data.total_tiempo_gracia = 0;
      }
      setSumaTotalGraciaRecaudo(response.data.total_tiempo_gracia);
      setTotalFacturasElectronicas(response.data.total_facturas_electronicas);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        return;
      }
      const response = await axiosInstance.get(URL, {
        params: {
          ...defaultParams,
          ...filterParams,
          page: pageIndex,
          ordering: ordering,
        },
      });
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 404) {

      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = () => {
    setFilterApplied(true);
  };

  // const handleEnterPress = () => {
  //   Lógica para manejar la tecla Enter, si es necesario
  // };

  // const handleClearClick = () => {
  //   handleSearchChange("");
  // };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder =
      sortField === id ? (order === "asc" ? "desc" : "asc") : "asc";

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === "desc" ? "-" : ""}${id}`);
  };

  const handleExport = async () => {
    try {
      const response = await axiosInstance.get(URL_EXPORT, {
        params: {
          ...filterParams,
        },
      });
      if (response.status === 200) {
        notifySuccess(
          "Se comenzo tarea de exportacion, por favor espere, este proceso puede tardar algunos minutos."
        );
      }
    } catch (error) {
      notifyError("Error al iniciar proceso de exportacion.");
    }
  };

  // Minuta Cambiar Text Factura
  const [showModalCambiarTextoFactrura, setShowModalCambiarTextoFactrura] =
    useState(false);

  const handleShowCambiarTextoFactrura = function () {
    setShowModalCambiarTextoFactrura(true);
  };
  const handleCloseCambiarTextoFactrura = () =>
    setShowModalCambiarTextoFactrura(false);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const getPdf = async (slug) => {
    try {
      setLoadingPdf(true);
      const response = await axiosInstance.get(`${URL}${slug}/pdf/`);
      return response.data.pdf;
    } catch (error) {
      handleErrors(error);
      return null;
    } finally {
      setLoadingPdf(false);
    }
  };

  const openDocumentModal = async (slug) => {
    setShowDocumentModal(true);
    const pdf = await getPdf(slug);
    if (pdf) {
      setSelectedDocument(pdf);
    }
  };

  const closeDocumentModal = () => {
    setShowDocumentModal(false);
    setSelectedDocument(null);
  };

  // Comentarios
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});
  const [selectedPrePostData, setSelectedPrePostData] = useState({});

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      visitante: slug,
      nota: "",
    });

    setUrlComentariosParams({
      visitante__slug: slug,
      page_size: 100,
    });
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  };

  const handleNewComment = (e) => {
    fetchUrl(1, searchValue, "");
  };
  // FIN COMENTARIOS

  const {
    page,
  } = tableInstance;

  const sendFacturaWhatsapp = (telefono, slug) => {
    Swal.fire({
      title: `¿Está seguro de re-enviar factura vía WhatsApp ${telefono}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, Enviar",
      confirmButtonColor: "#ff5e17",
      cancelButtonText: "No",
      cancelButtonColor: "#0f2242",
      reverseButtons: true,
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setLoadingWhatsapp(true);
        axiosInstance.post(`${URL}${slug}/enviar-factura-whatsapp/`).then((response) => {
          notifySuccess("Factura enviada correctamente");
        }).catch((error) => {
          handleErrors(error);
        }).finally(() => {
          setLoadingWhatsapp(false);
        });
      }
    });
  };


  const verFacturaElectronica = async (url) => {
    window.open(url, "_blank");
  };


  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="fs-20 mb-0 me-auto">Facturación electrónica</h4>
        <LinkDanger to={"/notas-creditos/"}>
          <BloqueoIcono /> Facturas anuladas
        </LinkDanger>
      </div>

      <div className="col-xl-12 col-lg-12 col-sm-12">
        <div className="card">
          <div className="card-headers pt-0 pb-0 text-center">
            <div className="row">
              <div className="col-3 pt-3 pb-3 border-end">
                <h3 className="mb-1 text-primary">
                  <CurrencyComponent amount={totalFacturasElectronicas} />
                </h3>
                <span>Facturas Restantes</span>
              </div>
              <div className="col-3 pt-3 pb-3 border-end">
                <h3 className="mb-1 text-primary">{sumaTotalGraciaRecaudo}</h3>
                <span>Total Tiempo Gracia</span>
              </div>
              <div className="col-3 pt-3 pb-3 border-end">
                <h3 className="mb-1 text-primary">
                  <CurrencyComponent amount={sumaTotalRecaudo} />
                </h3>
                <span>Total Recaudo</span>
              </div>
              <div className="col-3 pt-3 pb-3">
                <h3 className="mb-1 text-primary">{totalRecords}</h3>
                <span>Total Registros</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <div className="card-tools w-100">
              <div className="row align-items-center">
                <div className="input-group input-group-sm row">
                  <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                    <div className="px-2">
                      <input
                        type="text"
                        value={filterParams.search}
                        name="search"
                        id="search"
                        maxLength="100"
                        className="form-control form-control-sm rounded w-full h-50 align-items-center"
                        placeholder="Buscar por consecutivo, inmueble, placa y valor..."
                        onChange={(update) => {
                          let search = update.target.value;
                          setFilterParams({
                            ...filterParams,
                            search: search,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                    <DatePickerSingle
                      className="p-0"
                      selectedDate={startDateStart}
                      placeholderText="Fecha de Ingreso"
                      onChange={(date) => {
                        setDateRangeStart(date);
                        setFilterParams({
                          ...filterParams,
                          h_ingreso: date ? formatDate(date) : "",
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                    <DatePickerSingle
                      selectedDate={startDateEnd}
                      placeholderText="Fecha de Salida"
                      onChange={(date) => {
                        setDateRangeEnd(date);
                        setFilterParams({
                          ...filterParams,
                          h_salida: date ? formatDate(date) : "",
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 p-0 row align-items-center">
                    <ButtonGroup className="align-items-right position-relative">
                      <BotonPrimaryText
                        title="Click para buscar"
                        type="submit"
                        onClick={() => {
                          handleSearchChange();
                        }}
                      >
                        <i className="fa fa-search me-1"></i>
                        Filtrar
                      </BotonPrimaryText>
                      <BotonDangerText
                        title="Click para limpiar el filtro"
                        type="button"
                        onClick={() => {
                          setDateRangeStart(null);
                          setDateRangeEnd(null);
                          setFilterParams({
                            search: "",
                            h_ingreso_before: "",
                            h_ingreso_after: "",
                            h_salida_before: "",
                            h_salida_after: "",
                            h_ingreso: "",
                            h_salida: "",
                          });
                          setFilterApplied(true);
                        }}
                      >
                        <i className="fa fa-times me-1"></i>
                        Limpiar
                      </BotonDangerText>
                      <BotonPrimaryText
                        title="Click para exportar"
                        onClick={() => {
                          handleExport();
                        }}
                      >
                        <DescargarIcono />
                      </BotonPrimaryText>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            {loading ? (
              <p>
                <SpinnerCitoPlus text="Cargando datos..." />
              </p>
            ) : data.length === 0 ? (
              <p>No hay datos disponibles.</p>
            ) : (
              <Tables
                columns={columns}
                data={data}
                loading={loading}
                totalPages={totalPages}
                totalRecords={totalRecords}
                currentPage={currentPage}
                fetchUrl={fetchUrl}
                handleSortingChange={handleSortingChange}
                nextUrl={nextUrl}
                previousUrl={previousUrl}
                sortField={sortField}
                order={order}
                page={page}
              />
            )}
          </div>
        </div>
      </div>

      <ModalCambiarTextoFactura
        showModal={showModalCambiarTextoFactrura}
        onClose={handleCloseCambiarTextoFactrura}
      />

      <Comentarios
        showSidebarComments={showSidebarComments}
        setShowSidebarComments={setShowSidebarComments}
        // comentarios={selectedComentarios}
        urlComentarios={urlComentarios}
        urlComentariosParams={urlComentariosParams}
        urlPost={urlComentarios}
        selectedPrePostData={selectedPrePostData}
        handleNewComment={handleNewComment}
      />

      <ModalNotaCredito
        show={showModalNotaCredito}
        onHide={handleCloseNotaCredito}
        facturaId={facturaID}
      />
    </>
  );
};

export default FacturaElectronica;
