import { permisosServices, savePermissionsInLocalStorage } from '../../services/PermisosService';

import { CONFIRMED_GET_PERMISSIONS } from './ActionsTypes';

export function loginPermisosAction() {
  return (dispatch) => {
    permisosServices()
      .then((response) => {
        console.log("store loginPermisosAction", response.data);
        savePermissionsInLocalStorage(response.data);
        dispatch(confirmedGetPermisosAction(response.data));
      })
      .catch((error) => {
        console.error("Error fetching permisos data:", error);
      })
  };
}

export function confirmedGetPermisosAction(permisosData) {
  return {
    type: CONFIRMED_GET_PERMISSIONS,
    permisosData
  };
}

