import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';

const ModalAgregarFacturacionElectronica = ({ showModal, onClose }) => {


    const handleClose = () => {
        onClose();
    };

    return (
        <Modal show={showModal} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Agregar Datos para Facturación Electrónica</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre</label>
                            <input type="text" className="form-control" id="nombre" placeholder="Nombre" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="numero">Identificacion</label>
                            <input type="text" className="form-control" id="identificacion" placeholder="Identificacion" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="municipio">Ciudad</label>
                            <input type="text" className="form-control" id="ciudad" placeholder="Ciudad" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="estado">Departamento</label>
                            <input type="text" className="form-control" id="departamento" placeholder="Departamento" />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary">Guardar</button>
                <button className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAgregarFacturacionElectronica;
