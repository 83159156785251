import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../../../../services/AxiosInstance';
import swal from "sweetalert";
import CustomClearIndicator from '../../components/MultiSelect';
import Loading from '../../components/loading';
import { notifyError, notifySuccess } from '../../components/CitoPlusToastr';


const formInmuebleSchema = Yup.object().shape({
  interior: Yup.string()
    .required("Interior es un campo obligatorio.")
    .matches(/^[0-9]+$/, 'Interior debe ser números')
    .min(3, 'Inmueble debe tener mínimo 3 dígitos')
    .max(7, 'Inmueble debe tener máximo 7 dígitos')
    .nullable(true),
  numero1: Yup.string()
    .matches(/^57/, 'El número debe comenzar con el indicativo 57')
    .matches(/^\d{12}$/, 'El número debe tener 12 dígitos')
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true), // Permite que el valor sea null
  numero2: Yup.string()
    .matches(/^57/, 'El número debe comenzar con el indicativo 57')
    .matches(/^\d{12}$/, 'El número debe tener 12 dígitos')
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true), // Permite que el valor sea null
  numero3: Yup.string()
    .matches(/^57/, 'El número debe comenzar con el indicativo 57')
    .matches(/^\d{12}$/, 'El número debe tener 12 dígitos')
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true), // Permite que el valor sea null
  numero4: Yup.string()
    .matches(/^57/, 'El número debe comenzar con el indicativo 57')
    .matches(/^\d{12}$/, 'El número debe tener 12 dígitos')
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true), // Permite que el valor sea null
  numero5: Yup.string()
    .matches(/^57/, 'El número debe comenzar con el indicativo 57')
    .matches(/^\d{12}$/, 'El número debe tener 12 dígitos')
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true), // Permite que el valor sea null
  email: Yup.string()
    .email('El email debe ser un correo')
    .nullable(true),
  telefono_propietario: Yup.string()
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true),
  telefono_propietario_alternativo: Yup.string()
    .matches(/^[0-9]+$/, 'El número debe ser números')
    .nullable(true),
  coeficiente: Yup.number()
    .typeError('El coeficiente debe ser un número o decimal separado por punto')
    .min(0, 'El coeficiente debe ser un número positivo')
    .nullable(true),
})

const AddEditInmueblesModal = ({ showModal, onClose, updateInmueblesList, editMode, selectedInmuebleId }) => {

  const initialValuesEmpty = {
    grupos: [],
    nombre: null,
    numero1: null,
    numero2: null,
    numero3: null,
    numero4: null,
    numero5: null,
    interior: null,
    grupos: [],
    email: null,
    nombre_propietario: null,
    email_propietario: null,
    telefono_propietario: null,
    telefono_propietario_alternativo: null,
    consejo_admon: false,
    coeficiente: null
  }

  const URL = "/api/v3/usuarios/citofonos/";
  const URL_GRUPOS = '/api/v3/usuarios/grupos_copropiedades/dropdown/';
  const [grupoOptions, setGrupoOptions] = useState([]);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  useEffect(() => {
    const fetchGrupOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_GRUPOS);
        const data = await response.data;
        setGrupoOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchGrupOptions();

  }, [])

  useEffect(() => {
    const loadInitialValues = async () => {
      setLoadingInitialValues(true);
      try {
        if (editMode && selectedInmuebleId) {
          const data = await fetchDetailsInmueble();
          const initialValues = {
            grupos: data.grupo,
            interior: data.interior,
            email: data.email,
            nombre: data.nombre,
            numero1: data.numero1,
            numero2: data.numero2,
            numero3: data.numero3,
            numero4: data.numero4,
            numero5: data.numero5,
            nombre_propietario: data.nombre_p,
            email_propietario: data.email_p,
            telefono_propietario: data.numero_p1,
            telefono_propietario_alternativo: data.numero_p2,
            consejo_admon: data.consejo_admon,
            coeficiente: data.coeficiente
          };
          setInitialValues(initialValues);
        } else {
          const initialValues = initialValuesEmpty;
          setInitialValues(initialValues);
        }
      } catch (error) {
        console.error("Error fetching initial values:", error);
      } finally {
        setLoadingInitialValues(false);
      }
    };

    loadInitialValues();
  }, [editMode, selectedInmuebleId]);


  const fetchDetailsInmueble = async () => {
    try {
      const response = await axiosInstance.get(`${URL}${selectedInmuebleId}/`);
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm, setFieldError }) => {
    const postData = {
      nombre: values.nombre || null,
      numero1: values.numero1 || null,
      numero2: values.numero2 || null,
      numero3: values.numero3 || null,
      numero4: values.numero4 || null,
      numero5: values.numero5 || null,
      interior: values.interior || null,
      email: values.email || null,
      grupos_slugs: values.grupos || [],
      nombre_p: values.nombre_propietario || null,
      email_p: values.email_propietario || null,
      numero_p1: values.telefono_propietario || null,
      numero_p2: values.telefono_propietario_alternativo || null,
      consejo_admon: values.consejo_admon || false,
      coeficiente: values.coeficiente || null
    }
    try {
      if (editMode && selectedInmuebleId) {
        const response = await axiosInstance.patch(`${URL}${selectedInmuebleId}/`, postData);
      } else {
        const response = await axiosInstance.post(URL, postData);
      }
      // onSubmit();
      setInitialValues(initialValuesEmpty);
      onClose();
      const textSwal = editMode ? 'Inmueble Editado' : 'Inmueble Creado'
      updateInmueblesList();
      swal(textSwal, values.interior, "success");
    } catch (error) {


      if (error.response && error.response.data) {
        const apiErrors = error.response.data.errors || {};

        Object.keys(apiErrors).forEach((fieldName) => {
          let fieldConvert = fieldName;
          switch (fieldName) {
            case 'numero_p1':
              fieldConvert = 'telefono_propietario';
              break;
            case 'numero_p2':
              fieldConvert = 'telefono_propietario_alternativo';
              break;
            case 'email_p':
              fieldConvert = 'email_propietario';
              break;
            case 'nombre_p':
              fieldConvert = 'nombre_propietario';
              break;
            default:
              fieldConvert = fieldName;
          }

          let formattedErrors = null;

          if (Array.isArray(apiErrors[fieldName])) {
            formattedErrors = apiErrors[fieldName].map((error, index) => (
              <React.Fragment key={index}>
                {error.message || error}
                {index < apiErrors[fieldName].length - 1 && <br />} {/* Agrega <br /> entre errores */}
              </React.Fragment>
            ));
          } else if (typeof apiErrors[fieldName] === 'string') {
            formattedErrors = (
              <React.Fragment>
                {apiErrors[fieldName]}
              </React.Fragment>
            );
          } else if (typeof apiErrors[fieldName] === 'object' && apiErrors[fieldName] !== null) {
            formattedErrors = (
              <React.Fragment>
                {apiErrors[fieldName].detail}
              </React.Fragment>
            );

            notifyError(apiErrors[fieldName].detail);
          }
          setFieldError(apiErrors[fieldName].attr, formattedErrors);
        });
      } else {
        swal('Error', error.message, "error");
      }



    }
  };

  const handleCloseModal = () => {
    onClose();
    setLoadingInitialValues(false);
    setInitialValues({ initialValuesEmpty });
  }

  const handleNumberFocus = (event, setFieldValue, fieldName) => {
    const value = event.target.value;
    if (!value.startsWith('57')) {
      setFieldValue(fieldName, '57' + value);
    }
  };

  const handleNumberChange = (event, setFieldValue, fieldName) => {
    event.target.value = event.target.value.replace(/[^0-9+]/g, '');
    const value = event.target.value;
    setFieldValue(fieldName, value);
    return true;
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      {loadingInitialValues ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={formInmuebleSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <div role="document">
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">
                    {editMode ? 'Editar Inmueble' : 'Crear Inmueble'}
                  </h4>
                  <button type="button" className="btn-close" onClick={onClose} data-dismiss="modal">
                    <span></span>
                  </button>
                </div>
                <div className="modal-body">
                  <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                  <div className="add-contact-box">
                    <div className="row">
                      <fieldset>
                        <legend className="text-uppercase">Información Inmueble</legend>

                        <div className="form-group mb-3 row">
                          <div
                            className="col-lg-4 col-form-label css-control-primary css-checkbox"
                            htmlFor="consejo_admon"
                          >
                            Consejo admon
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="checkbox"
                              id="consejo_admon"
                              name="consejo_admon"
                              className="form-check-input me-2 mt-0"
                              checked={values.consejo_admon || false}
                              value={values.consejo_admon || false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <div className="col-lg-4 col-form-label" htmlFor="grupo">
                            Coeficiente
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              step="any"
                              className="form-control"
                              id="coeficiente"
                              name="coeficiente"
                              value={values.coeficiente || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-coeficiente-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.coeficiente}
                            </div>
                          </div>
                        </div>


                        <div className="form-group mb-3 row">
                          <div className="col-lg-4 col-form-label" htmlFor="grupo">
                            Grupo
                          </div>
                          <div className="col-lg-7">
                            <CustomClearIndicator
                              options={grupoOptions}
                              onChange={(selectedOptions) => {
                                setFieldValue('grupos', selectedOptions.map(option => option.value));
                              }}
                              defaultsValues={values.grupos}
                            />

                            <ErrorMessage name="grupo" component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="interior">
                            Inmueble
                            <span className="text-danger">*</span>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="interior"
                              name="interior"
                              value={values.interior || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={editMode && selectedInmuebleId ? true : false}
                            />
                            {editMode ? <small id="interiorHelp" className="form-text text-muted">Este dato no es editable</small> : null}
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.interior}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="Correo Electrónico">
                            Correo Electrónico
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              value={values.email || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.email}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="nombre">
                            Nombre
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="nombre"
                              name="nombre"
                              value={values.nombre || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.nombre}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="numero1">
                            Número 1
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="numero1"
                              name="numero1"
                              value={values.numero1 || ""}
                              onChange={(e) => handleNumberChange(e, setFieldValue, 'numero1')}
                              onFocus={(e) => handleNumberFocus(e, setFieldValue, 'numero1')}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.numero1}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="numero2">
                            Número 2
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="numero2"
                              name="numero2"
                              value={values.numero2 || ""}
                              onChange={(e) => handleNumberChange(e, setFieldValue, 'numero2')}
                              onFocus={(e) => handleNumberFocus(e, setFieldValue, 'numero2')}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.numero2}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="numero3">
                            Número 3
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="numero3"
                              name="numero3"
                              value={values.numero3 || ""}
                              onChange={(e) => handleNumberChange(e, setFieldValue, 'numero3')}
                              onFocus={(e) => handleNumberFocus(e, setFieldValue, 'numero3')}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.numero3}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="numero4">
                            Número 4
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="numero4"
                              name="numero4"
                              value={values.numero4 || ""}
                              onChange={(e) => handleNumberChange(e, setFieldValue, 'numero4')}
                              onFocus={(e) => handleNumberFocus(e, setFieldValue, 'numero4')}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.numero4}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="numero5">
                            Número 5
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="numero5"
                              name="numero5"
                              value={values.numero5 || ""}
                              onChange={(e) => handleNumberChange(e, setFieldValue, 'numero5')}
                              onFocus={(e) => handleNumberFocus(e, setFieldValue, 'numero5')}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.numero5}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend className="text-uppercase">Informacion Propietario</legend>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="nombre_propietario">
                            Nombre Propietario
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="nombre_propietario"
                              name="nombre_propietario"
                              value={values.nombre_propietario || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.nombre_propietario}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="email_propietario">
                            Correo Electrónico Propietario
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="email_propietario"
                              name="email_propietario"
                              value={values.email_propietario || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.email_propietario}
                            </div>
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <div className='col-lg-4 col-form-label' htmlFor="telefono_propietario">
                            Teléfono Propietario
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="telefono_propietario"
                              name="telefono_propietario"
                              value={values.telefono_propietario || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.telefono_propietario}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className='col-lg-4 col-form-label' htmlFor="telefono_propietario_alternativo">
                            Teléfono Propietario Alternativo
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="telefono_propietario_alternativo"
                              name="telefono_propietario_alternativo"
                              value={values.telefono_propietario_alternativo || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.telefono_propietario_alternativo}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="btn btn-citoplus-danger"
                  >
                    <i className="flaticon-delete-1"></i> Cancelar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-citoplus-primary"
                    disabled={isSubmitting}>
                    Agregar
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddEditInmueblesModal;
