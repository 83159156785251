import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = (options) => {
  toast(options.text, {
    type: options.type || "default",
    position: options.position || "top-center",
    autoClose: options.autoClose || 5000,
    hideProgressBar: options.hideProgressBar || false,
    closeOnClick: options.closeOnClick || true,
    pauseOnHover: options.pauseOnHover || true,
    draggable: options.draggable || true,
    progress: options.progress || undefined
  });
};

export const notifySuccess = (text, options = {}) => {
  notify({ text, ...options, type: "success",  });
};

export const notifyError = (text, options = {}) => {
  notify({
    text,
    ...options,
    type: "error"
  });
};

export const notifyInfo = (text, options = {}) => {
  notify({ text, ...options, type: "info" });
}

export const notifyWarning = (text, options = {}) => {
  notify({ text, ...options, type: "warning" });
};

// Puedes añadir más funciones de notificación aquí según tus necesidades

export const CustomToastContainer = () => {
  return <ToastContainer />;
};
