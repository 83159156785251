import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from 'services/AxiosInstance';
import { useTable, useFilters, usePagination } from "react-table";
import { notifyError } from '../../components/CitoPlusToastr';
import { SpinnerCitoPlus } from 'jsx/components/CitoPlus/components/loadingLogo';
import Tables from "jsx/components/CitoPlus/components/tables";

const NotasCreditos = () => {
  // Variable para mostrar el mensaje cargando
  const [loading, setLoading] = useState(true);

  // Generación de la data correpondiente a la tabla
  const [data, setData] = useState([]);

  // Generación de las variables para los filtros
  const [searchValue, setSearchValue] = useState("");
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  // Generación de variables para la paginación de la tabla
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (row, i) => i + 1,
        disableFilters: true,
        disableSortable: true,
      },
      {
        Header: "Motivo",
        disableFilters: true,
        disableSortable: true,
        accessor: 'nota_credito_info_adicional',
      },
      {
        Header: "Monto",
        accessor: "nota_credito_valor",
        disableFilters: true,
        disableSortable: true,
      },
      {
        Header: "Fecha",
        accessor: 'created_at',
        disableFilters: true,
        disableSortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  const fetchUrl = async (pageIndex = 1) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/v3/parking/notas-credito-parqueaderos/', {
        params: {
          page: pageIndex,
        },
      });
      setData(response.data.results);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
    } catch (error) {
      notifyError('Error al cargar las notas de crédito');
    } finally {
      setLoading(false);
    }
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder =
      sortField === id ? (order === "asc" ? "desc" : "asc") : "asc";

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === "desc" ? "-" : ""}${id}`);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    page,
  } = tableInstance;

  return (
    <div className="col-xl-12 col-lg-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="mb-4">Notas de Crédito</h4>
        </div>
        <div className="card-body">
          {loading ? (
            <p>
              <SpinnerCitoPlus text="Cargando datos..." />
            </p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              totalRecords={totalRecords}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotasCreditos;
