export const MenuListVigilante = [
    // Inicio
    {
      title: 'Inicio',
      iconStyle: <i className="fas fa-home"></i>,
      to: 'dashboard',
    },
    {
      title: 'Cajas',
      classChange: 'mm-collapse',
      iconStyle: <i className="fas fa-cash-register"></i>,
      content: [
        {
          title: 'Cierre vehicular',
          to: 'cajas'
        },
      ]
    },
    {
      title: 'Visitantes',
      classChange: 'mm-collapse',
      iconStyle: <i className="fas fa-users"></i>,
      content: [
        {
          title: 'Vehicular',
          to: 'visitantes-vehiculares'
        },
        {
          title: 'Peatonal',
          to: 'visitantes-peatonales'
        },
        {
          title: 'Registros',
          to: 'visitantes-historial'
        },
      ]
    },
    {
      title: "Parqueaderos",
      iconStyle: <i className="fas fa-parking"></i>,
      to: 'parqueaderos',
    },
    {
      title: "Correspondencia",
      iconStyle: <i className="fas fa-envelope"></i>,
      to: 'correspondencia',
    },
    {
      title: "Autorizaciones",
      iconStyle: <i className="fas fa-clipboard-check"></i>,
      to: 'autorizaciones',
    },
    {
      title: "Minuta",
      iconStyle: <i className="fas fa-book"></i>,
      to: 'minuta',
    },
  ]
