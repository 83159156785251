import React from 'react';

const formatNumber = (number) => {
  // convertir a numero
  number = Number(number);

  // Verificar si el número es NaN
  if (isNaN(number)) {
    return '0';
  }

  // Verificar si el número es mayor o igual a 1 billón
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + ' MM';
  }
  // Verificar si el número es mayor o igual a 1 millón
  else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + ' M';
  }
  // Verificar si el número es mayor o igual a 1 mil
  else if (number >= 1e3) {
    return (number / 1e3).toFixed(0) + 'K';
  }
  // Para otros casos, mostrar el número tal como está
  else {
    return number?.toString();
  }
};

export const CurrencyComponent = ({ amount }) => {
  // Formatear el número en formato de pesos mexicanos
  /* const formattedAmount = amount.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 2,
  });*/
  if (amount === '0' || amount === null || amount === undefined) {
    return <span>0</span>;
  }
  const formattedAmount = formatNumber(amount);
  return <span>{formattedAmount}</span>;
};

export const CurrencyNormalComponent = ({ amount }) => {
  // Formatear el número en formato de pesos colombianos
  const formattedAmount = amount.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 2,
  });

  return <span>{formattedAmount}</span>;
};
