import React, { useEffect } from "react";
import axiosInstance from "services/AxiosInstance";
import { BotonPrimaryText, BotonPrimary, BotonInfoText, BotonSuccessText, BotonDangerText } from "jsx/components/CitoPlus/components/BotonesCustom";
import { SpinnerCitoPlus } from "jsx/components/CitoPlus/components/loadingLogo";
import VigilantesCustomCard from "jsx/components/CitoPlus/components/VigilantesCustomCard";
import ingreso_vehicular from '../../../../images/vigilantes/ingreso_vehicular_transparente.png';
import { Col, Form } from "react-bootstrap";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import HelpIcon from "jsx/components/CitoPlus/components/HelpIcon";


const Parqueaderos = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [totalRegistros, setTotalRegistros] = React.useState(0);
  const [parqueaderosData, setParqueaderosData] = React.useState([]);
  const [tipoParqueaderoFilter, setTipoParqueaderoFilter] = React.useState('');

  const URL = '/api/v3/vigilancia/parqueaderos/';
  const URL_ENTRADA = `/api/v3/vigilancia/parqueaderos/{uuid}/entrada/`;
  const URL_SALIDA = "/api/v3/vigilancia/parqueaderos/{uuid}/salida/";

  useEffect(() => {
    fetchData();
  }, []);

  const handleClearClick = () => {
    setSearchTerm('');
    setTipoParqueaderoFilter('');
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL);
      setTotalRegistros(response.data.length);
      setParqueaderosData(response.data);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }

  // Obtener tipos de parqueaderos únicos
  const tiposParqueaderos = [...new Set(parqueaderosData.map(p => p.tipo_parqueadero))].filter(Boolean).sort();

  // Filtrar los datos basados en el término de búsqueda y tipo de parqueadero
  const filteredData = parqueaderosData.filter(parqueadero => {
    // Filtrar por tipo de parqueadero
    if (tipoParqueaderoFilter && parqueadero.tipo_parqueadero !== tipoParqueaderoFilter) {
      return false;
    }

    // Filtrar por término de búsqueda
    if (!searchTerm.trim()) return true;

    const searchTermLower = searchTerm.toLowerCase();
    return (
      (parqueadero.tipo_parqueadero && parqueadero.tipo_parqueadero.toLowerCase().includes(searchTermLower)) ||
      (parqueadero.no_parqueadero && parqueadero.no_parqueadero.toLowerCase().includes(searchTermLower)) ||
      (parqueadero.placa && parqueadero.placa.toLowerCase().includes(searchTermLower)) ||
      (parqueadero.tipo_vehiculo && parqueadero.tipo_vehiculo.toLowerCase().includes(searchTermLower)) ||
      (parqueadero.marca && parqueadero.marca.toLowerCase().includes(searchTermLower)) ||
      (parqueadero.color && parqueadero.color.toLowerCase().includes(searchTermLower))
    );
  });

  // Manejadores para entrada y salida de vehículos
  const handleEntrada = (parqueadero) => {
    axiosInstance.post(URL_ENTRADA.replace('{uuid}', parqueadero.vehiculo_uuid))
      .then(response => {
        fetchData();
      })
      .catch(error => {
        console.log('error:', error);
      }
    );
  };

  const handleSalida = (parqueadero) => {
    axiosInstance.post(URL_SALIDA.replace('{uuid}', parqueadero.vehiculo_uuid))
      .then(response => {
        fetchData();
      })
      .catch(error => {
        console.log('error:', error);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="card-title">
          Parqueaderos
          {loading ? (
            <small className="text-muted ms-2">(0 registros)</small>
          ) : (
            <small className="text-muted ms-2">({filteredData.length} de {totalRegistros} registros)</small>
          )}
          <HelpIcon>
            Solo las tarjetas de parqueaderos <strong>PRIVADOS</strong> con <strong>PLACA</strong> asignada muestran botones de entrada/salida.
          </HelpIcon>
        </h4>
        <div className='ms-auto'>
          <div className='d-flex align-items-center'>
            <Form.Select
              className="me-2 form-control-sm"
              value={tipoParqueaderoFilter}
              onChange={(e) => setTipoParqueaderoFilter(e.target.value)}
              style={{ minWidth: '150px' }}
            >
              <option value="">Todos los tipos</option>
              {tiposParqueaderos.map((tipo, idx) => (
                <option key={idx} value={tipo}>{tipo}</option>
              ))}
            </Form.Select>
            <input
              type='text'
              className='form-control form-control-sm me-2'
              placeholder='Buscar...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='me-2'>
              <BotonPrimary
                title="Limpiar Filtro"
                onClick={handleClearClick}
              >
                <i className="fa fa-times"></i>
              </BotonPrimary>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <SpinnerCitoPlus text="Cargando Visitantes Vehiculares..." />
      ) : totalRegistros === 0 ? (
        <Col xl={12} className="text-center">
          <img src={ingreso_vehicular} alt="No hay correspondencia pendiente" className="img-fluid" width={"30%"} />
          <h3 className="mt-3">No hay Visitantes Vehiculares</h3>
        </Col>
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              {filteredData.map((parqueadero, index) => (
                <div className="col-xl-6" key={index}>
                  <VigilantesCustomCard
                    title={parqueadero.tipo_parqueadero}
                    subtitle={parqueadero.no_parqueadero}
                    inmueble={parqueadero.inmueble}
                    image={parqueadero.foto}
                    items={[
                      { title: 'Placa', value: parqueadero.placa },
                      { title: 'Tipo Vehículo', value: parqueadero.tipo_vehiculo },
                      { title: 'Marca', value: parqueadero.marca },
                      { title: 'Color', value: parqueadero.color },
                      { title: 'Fecha de Creación', value: parqueadero.created_at },
                    ]}
                    loading={loading}
                    imageClickable={true}
                    footerButtons={
                      ["COMUN", "PRIVADA", "BICICLETERO"].includes(parqueadero.tipo_parqueadero) && parqueadero.placa ? [
                        parqueadero.vehiculo_estado === 'entrada' ? (
                          <BotonDangerText
                            title="Registrar Salida"
                            onClick={() => handleSalida(parqueadero)}
                          >
                            <i className="fa fa-sign-out me-1"></i> Salida
                          </BotonDangerText>
                        ) : parqueadero.vehiculo_estado === 'salida' ? (
                          <BotonSuccessText
                            title="Registrar Entrada"
                            onClick={() => handleEntrada(parqueadero)}
                          >
                            <i className="fa fa-car me-1"></i> Entrada
                          </BotonSuccessText>
                        ) : (
                          <BotonSuccessText
                            title="Registrar Entrada"
                            onClick={() => handleEntrada(parqueadero)}
                          >
                            <i className="fa fa-car me-1"></i> Entrada
                          </BotonSuccessText>
                        )
                      ] : []
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Parqueaderos;

// ...existing code...