import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  logout,
} from '../../services/AuthService';

import { loginPermisosAction } from '../../store/actions/PermisosActions';
import {
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOADING_TOGGLE_ACTION,
  LOGOUT_ACTION
} from './ActionsTypes';

export function signupAction(email, password, navigate) {

  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
        response.data.expiresIn = new Date(
          new Date().getTime() + twelveHoursInMilliseconds,
        ) * 1000;
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000,
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate('/dashboard');
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  logout();
  localStorage.removeItem('userDetails');
  localStorage.removeItem('userPermissions');
  navigate('/login');
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        let newTokenDetails = saveTokenInLocalStorage(response.data);
        dispatch(loginConfirmedAction(newTokenDetails));

        // Solicitar Permisos
        dispatch(loginPermisosAction());
        navigate('/dashboard');
      })
      .catch((error) => {
        // Asegúrate de que error.response existe y tiene propiedades antes de intentar acceder a ellas
        if (error.response && error.response.data) {
          const errorMessage = formatError(error.response.data);
          dispatch(loginFailedAction(errorMessage));
        } else {
          // Manejar el error cuando no se puede obtener error.response.data
          const errorMessage = formatError(error);
          dispatch(loginFailedAction(errorMessage));
        }
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
