import React, { useMemo, useEffect, useState, useCallback } from 'react';
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchWithDateRange from '../components/SearchWithDateRange';

import noImagen from '../../../../images/no-image.jpg';
import { CitoPlusLinkIconPrimary } from '../components/CitoPlusLink';
import BusquedaVisitantes from 'jsx/components/CitoPlus/components/BusquedaVisitantes';

import ExportButton from 'jsx/components/CitoPlus/components/ExportarBoton';


export const RegistrosVisitantes = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const [format, setFormat] = useState('csv');

  const URL = '/api/v3/visitantes/ingreso/';
  const URL_DOWNLOAD = '/api/v3/visitantes/ingreso/exportar/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    {
      id: 'foto',
      Header: 'foto',
      accessor: 'foto',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Foto</div>
      ),
      Cell: ({ value }) => (
        value === null ? (
          <img
            src={noImagen}
            alt=""
            className="me-3 rounded"
            width={75}
          />
        ) : (
          <div className='image-container me-3 rounded'>
            <a href={value} data-lightbox="image">
              <img
                src={value}
                alt=""
                className="me-3 rounded"
                width={75}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = noImagen;
                }}
              />
            </a>
          </div>
        )
      ),
    },
    {
      id: 'info_visitante',
      Header: "Info. Visitante",
      disableFilters: true,
      disableSortBy: true,
      accessor: row => {
        return (
          <div className="pt-4 left-align-cell">
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Visitante:</h5>
              <p className='paragraph'>{row.visitante_nombre}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Inmueble:</h5>
              <p className='paragraph'>{row.unidad_visita}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: 'detalles',
      accessor: 'detalles',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Detalles</div>
      ),
      accessor: row => {
        return (
          <div className="pt-4 left-align-cell">
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Ingreso:</h5>
              <p className='paragraph'>{row.h_ingreso}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Salida:</h5>
              <p className='paragraph'>{row.h_salida}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: 'vigilantes',
      Header: 'Vigilantes',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => (
        <div className="pt-4 left-align-cell">
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Ingreso:</h5>
            <p className='paragraph'>{row.vigilante_ingreso_full_name}</p>
          </div>
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Salida:</h5>
            <p className='paragraph'>{row.vigilante_salida_full_name}</p>
          </div>
        </div>
      )
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <div className="">
          <CitoPlusLinkIconPrimary
            to={`/registros-visitantes/${row.slug}`}
          >
            <i className="fa fa-info-circle"></i>
          </CitoPlusLinkIconPrimary>
        </div>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, filterParams = {}, ordering = []) => {
    defaultParams.page = pageIndex;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);

      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario

  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Registros</h4>
        <div className='ms-auto'>
          <ExportButton url={URL_DOWNLOAD} nombreArchivo='registros'  />
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <BusquedaVisitantes
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
              placeholder="Buscar..."
              placeholderText="Fecha de Ingreso ..."
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
              totalRecords={totalRecords}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default RegistrosVisitantes;
