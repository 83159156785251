import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const HelpPopover = ({ children }) => {
  const popover = (
    <Popover id="popover-help">
      <Popover.Header as="h3">Ayuda</Popover.Header>
      <Popover.Body>
        {children}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
      <i className="fa fa-question-circle ms-2 text-primary" style={{ cursor: 'pointer' }} />
    </OverlayTrigger>
  );
};

export default HelpPopover;
